<template>
  <div class="row">
    <div class="col-md-12">
      <h2 class="fw-bolder">
        <a
          href="javascript:void(0)"
          class="text-black fas fa-angle-left"
          style="margin-right: 15px"
          @click="goBack"
        >
        </a
        >{{ title }}
      </h2>
    </div>
  </div>
  <div class="card mb-5 mb-xl-10">
    <div class="card-body p-9">
      <el-form
        @submit.prevent="submit()"
        id="form"
        ref="formRef"
        class="form"
        :model="model"
        :rules="rules"
        :label-position="fields.label_position"
        :label-width="fields.label_width"
      >
        <template v-for="(field, index) in fields.list" :key="index">
          <template v-if="field.type == 'row'">
            <div v-if="field.grid == 3" class="row">
              <template
                v-for="(sub_field, index2) in field.subfields"
                :key="index2"
              >
                <div v-if="sub_field.type == 'input'" class="col-md-4 mb-2">
                  <el-form-item :prop="sub_field.key" :label="sub_field.label">
                    <el-input
                      v-bind:id="sub_field.key"
                      v-model="model[sub_field.key]"
                      :placeholder="sub_field.placeholder"
                      :disabled="sub_field.disabled"
                      :maxlength="
                        sub_field.maxlength ? sub_field.maxlength : 255
                      "
                    >
                      <!-- <el-input
                                            v-bind:id="sub_field.key"
                                            v-model="model[sub_field.key]"
                                            :placeholder="sub_field.placeholder"
                                            :disabled="sub_field.disabled"
                                            :maxlength="sub_field.maxlength ? sub_field.maxlength : 255"
                                            show-word-limit
                                        > -->
                    </el-input>
                  </el-form-item>
                </div>
                <div
                  v-if="sub_field.type == 'textarea_limit'"
                  class="col-md-4 mb-2"
                >
                  <el-form-item :prop="sub_field.key" :label="sub_field.label">
                    <el-input
                      v-bind:id="sub_field.key"
                      v-model="model[sub_field.key]"
                      type="textarea"
                      :rows="sub_field.row"
                      :name="sub_field.key"
                      :placeholder="sub_field.placeholder"
                      :disabled="sub_field.disabled"
                      :maxlength="
                        sub_field.maxlength ? sub_field.maxlength : 255
                      "
                    >
                    </el-input>
                  </el-form-item>
                </div>
                <div v-if="sub_field.type == 'textarea'" class="col-md-4 mb-2">
                  <el-form-item :prop="sub_field.key" :label="sub_field.label">
                    <el-input
                      v-bind:id="sub_field.key"
                      v-model="model[sub_field.key]"
                      type="textarea"
                      :rows="sub_field.row"
                      :name="sub_field.key"
                      :placeholder="sub_field.placeholder"
                      :disabled="sub_field.disabled"
                    >
                    </el-input>
                  </el-form-item>
                </div>
                <div v-if="sub_field.type == 'duedate'" class="col-md-4 mb-2">
                  <el-form-item :prop="sub_field.key" :label="sub_field.label">
                    <el-date-picker
                      v-model="model[sub_field.key]"
                      :placeholder="sub_field.placeholder"
                      :name="'dueDate' + sub_field.key"
                      :popper-class="'dateTime' + sub_field.key"
                    />
                  </el-form-item>
                </div>
                <template v-else-if="sub_field.type == 'hr'">
                  <hr class="mt-4 mb-4 text-muted" />
                </template>
                <div
                  v-else-if="sub_field.type == 'number'"
                  class="col-md-4 mb-2"
                >
                  <el-form-item :prop="sub_field.key" :label="sub_field.label">
                    <el-input
                      v-model="model[sub_field.key]"
                      :placeholder="sub_field.placeholder"
                      type="number"
                      :disabled="sub_field.disabled"
                      :min="sub_field.min ? sub_field.min : 0"
                      :maxlength="
                        sub_field.maxlength ? sub_field.maxlength : 255
                      "
                    >
                    </el-input>
                  </el-form-item>
                </div>
                <div
                  v-else-if="sub_field.type == 'select'"
                  class="col-md-4 mb-2"
                >
                  <el-form-item :prop="sub_field.key" :label="sub_field.label">
                    <el-select
                      v-model="model[sub_field.key]"
                      :placeholder="sub_field.placeholder"
                      filterable
                      v-bind:id="'select' + sub_field.key"
                      :loading="loadingSelect"
                      :disabled="sub_field.disabled"
                      clearable
                      :popper-class="sub_field.key + 'Select'"
                      @click="selectAttr(sub_field.key)"
                    >
                      <el-option
                        v-for="(nilai, index) in sub_field.options"
                        :key="index"
                        :label="nilai.label"
                        :value="nilai.value"
                      />
                    </el-select>
                  </el-form-item>
                </div>
                <div
                  v-else-if="sub_field.type == 'select_tags'"
                  class="col-md-4 mb-2"
                >
                  <el-form-item :prop="sub_field.key" :label="sub_field.label">
                    <el-select
                      v-model="model[sub_field.key]"
                      :placeholder="sub_field.placeholder"
                      filterable
                      multiple
                      v-bind:id="'selectTags' + sub_field.key"
                      :tag-type="sub_field.colorTag"
                      :loading="loadingSelect"
                      clearable
                      :disabled="sub_field.disabled"
                      :popper-class="sub_field.key + 'SelectTags'"
                      @click="selectTagAttr(sub_field.key)"
                    >
                      <el-option
                        v-for="(nilai, index) in sub_field.options"
                        :key="index"
                        :label="nilai.label"
                        :value="nilai.value"
                      />
                    </el-select>
                  </el-form-item>
                </div>
                <div
                  v-else-if="sub_field.type == 'custom'"
                  class="col-md-4 mb-2"
                >
                  <slot :name="sub_field.key"></slot>
                </div>
                <div
                  v-else-if="sub_field.type == 'upload_pdf'"
                  class="col-md-4 mb-2"
                >
                  <el-form-item
                    :prop="sub_field.key"
                    :label="sub_field.label"
                    align="right"
                  >
                    <div class="input-group">
                      <input
                        type="text"
                        v-bind:id="sub_field.url"
                        class="form-control"
                        v-model="model[sub_field.key]"
                        @click="$refs[sub_field.keyUrl][0].click()"
                        :disabled="sub_field.disabled"
                        :placeholder="sub_field.placeholder"
                        readonly
                      />
                      <label class="input-group-btn">
                        <span class="btn btn-dark">
                          <template v-if="sub_field.disabled">
                            <span class="fa fa-folder"></span>
                          </template>
                          <template v-else>
                            <input
                              v-bind:id="sub_field.keyUrl"
                              :ref="sub_field.keyUrl"
                              @change="
                                changeFile(
                                  $event,
                                  sub_field.key,
                                  sub_field.keyUrl
                                )
                              "
                              type="file"
                              style="display: none"
                              accept=".pdf"
                            />
                            <span class="fa fa-folder"></span>
                          </template>
                        </span>
                      </label>
                    </div>
                    <span class="text-muted"
                      >File Maksimal 10MB, format .pdf</span
                    >
                  </el-form-item>
                </div>
                <div
                  v-else-if="sub_field.type == 'upload_img'"
                  class="col-md-4 mb-2"
                >
                  <el-form-item
                    :prop="sub_field.key"
                    :label="sub_field.label"
                    align="right"
                  >
                    <div class="input-group">
                      <input
                        type="text"
                        v-bind:id="sub_field.url"
                        class="form-control"
                        v-model="model[sub_field.key]"
                        @click="$refs[sub_field.keyUrl][0].click()"
                        :disabled="sub_field.disabled"
                        :placeholder="sub_field.placeholder"
                        readonly
                      />
                      <label class="input-group-btn">
                        <span class="btn btn-dark">
                          <template v-if="sub_field.disabled">
                            <span class="fa fa-image"></span>
                          </template>
                          <template v-else>
                            <input
                              v-bind:id="sub_field.keyUrl"
                              :ref="sub_field.keyUrl"
                              @change="
                                changeFileImage(
                                  $event,
                                  sub_field.key,
                                  sub_field.keyUrl
                                )
                              "
                              type="file"
                              style="display: none"
                              accept=".jpeg,.png,.jpg"
                            />
                            <span class="fa fa-image"></span>
                          </template>
                        </span>
                      </label>
                    </div>
                    <template v-if="model[sub_field.key] != null">
                      <a
                        v-if="this.$route.params.id"
                        href="javascript:void(0)"
                        @click="openFile(model[sub_field.key])"
                        style="position: absolute; left: 0"
                        >Lihat Gambar</a
                      >
                    </template>
                    <span class="text-muted"
                      >File Maksimal 10MB, format .jpeg,.png,.jpg</span
                    >
                  </el-form-item>
                </div>
                <div
                  v-else-if="sub_field.type == 'upload'"
                  class="col-md-4 mb-2"
                >
                  <el-form-item
                    :prop="sub_field.key"
                    :label="sub_field.label"
                    align="right"
                  >
                    <div class="input-group">
                      <input
                        type="text"
                        v-bind:id="sub_field.url"
                        class="form-control"
                        v-model="model[sub_field.key]"
                        @click="$refs[sub_field.keyUrl][0].click()"
                        :disabled="sub_field.disabled"
                        :placeholder="sub_field.placeholder"
                        readonly
                      />
                      <label class="input-group-btn">
                        <span class="btn btn-dark">
                          <template v-if="sub_field.disabled">
                            <span class="fa fa-file"></span>
                          </template>
                          <template v-else>
                            <input
                              v-bind:id="sub_field.keyUrl"
                              :ref="sub_field.keyUrl"
                              @change="
                                changeFileAll(
                                  $event,
                                  sub_field.key,
                                  sub_field.keyUrl
                                )
                              "
                              type="file"
                              style="display: none"
                            />
                            <span class="fa fa-file"></span>
                          </template>
                        </span>
                      </label>
                    </div>
                    <span class="text-muted">File Maksimal 10MB</span>
                  </el-form-item>
                </div>
                <div v-if="sub_field.type == 'switch'" class="col-md-4 mb-2">
                  <el-form-item :prop="sub_field.key" :label="sub_field.label">
                    <label
                      class="form-check form-switch form-check-custom form-check-solid"
                      :prop="sub_field.key"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        :true-value="true"
                        :false-value="false"
                        v-model="model[sub_field.key]"
                        v-bind:id="sub_field.key"
                        :disabled="sub_field.disabled"
                      />
                      <span class="form-check-label fw-bold"> </span>
                    </label>
                  </el-form-item>
                </div>
                <div v-if="sub_field.type == 'radio'" class="col-md-4 mb-2">
                  <el-form-item :prop="sub_field.key" :label="sub_field.label">
                    <div class="d-flex align-items-center">
                      <label
                        class="form-check form-check-custom form-check-solid me-10"
                        v-for="(data, index) in sub_field.options"
                        :key="index"
                      >
                        <input
                          v-bind:id="sub_field.key + data.label + (index + 1)"
                          class="form-check-input h-20px w-20px"
                          type="radio"
                          :value="data.value"
                          :disabled="sub_field.disabled"
                          v-model="radios[sub_field.key]"
                          @change="handleInputRadio($event, sub_field.key)"
                        />
                        <span class="form-check-label fw-bold">
                          {{ data.label }}
                        </span>
                      </label>
                    </div>
                  </el-form-item>
                </div>
                <div v-if="sub_field.type == 'checkbox'" class="col-md-4 mb-2">
                  <el-form-item :prop="sub_field.key">
                    <div class="d-flex flex-stack mb-8">
                      <div class="me-5 d-flex flex-column">
                        <label class="fs-6">
                          {{ sub_field.label }}
                          <span
                            v-if="
                              sub_field.required != undefined
                                ? sub_field.required
                                : false
                            "
                            class="required"
                          ></span>
                        </label>
                        <span class="fs-7 text-muted">{{
                          sub_field.label2
                        }}</span>
                      </div>
                      <div class="d-flex align-items-center">
                        <label
                          class="form-check form-check-custom form-check-solid me-10"
                        >
                          <input
                            class="form-check-input h-20px w-20px"
                            type="checkbox"
                            v-bind:id="sub_field.key"
                            :true-value="sub_field.true_value"
                            :false-value="sub_field.false_value"
                            v-model="model[sub_field.key]"
                          />
                          <label
                            class="form-check-label fw-bold"
                            v-bind:for="sub_field.key"
                            >{{ sub_field.data_value }}</label
                          >
                          &nbsp;
                        </label>
                      </div>
                    </div>
                  </el-form-item>
                </div>
                <div
                  v-if="sub_field.type == 'multi_checkbox'"
                  class="col-md-4 mb-2"
                >
                  <el-form-item :prop="sub_field.key">
                    <div class="d-flex flex-stack mb-8">
                      <div class="me-5 d-flex flex-column">
                        <label class="fs-6">
                          {{ sub_field.label }}
                          <span
                            v-if="
                              sub_field.required != undefined
                                ? sub_field.required
                                : false
                            "
                            class="required"
                          ></span>
                        </label>
                        <span class="fs-7 text-muted">{{
                          sub_field.label2
                        }}</span>
                      </div>
                      <div class="d-flex align-items-center">
                        <label
                          class="form-check form-check-custom form-check-solid me-10"
                          prop="selected"
                          v-for="(data, index3) in sub_field.options"
                          :key="index3"
                        >
                          <input
                            class="form-check-input h-20px w-20px"
                            type="checkbox"
                            v-bind:id="
                              'multiCheckbox' + sub_field.key + (index3 + 1)
                            "
                            :value="data.value"
                            v-model="model[sub_field.key]"
                          />
                          <span class="form-check-label fw-bold">
                            {{ data.label }}
                          </span>
                        </label>
                      </div>
                    </div>
                  </el-form-item>
                </div>
                <div
                  v-if="sub_field.type == 'num_rupiah'"
                  class="col-md-4 mb-2"
                >
                  <el-form-item :prop="sub_field.key" :label="sub_field.label">
                    <div class="input-group mb-5">
                      <span class="input-group-text" id="basic-addon1"
                        >Rp.</span
                      >
                      <input
                        type="number"
                        class="form-control"
                        :placeholder="sub_field.placeholder"
                        :readonly="sub_field.disabled"
                      />
                    </div>
                  </el-form-item>
                </div>
              </template>
            </div>
            <div v-else class="row">
              <template
                v-for="(sub_field, index2) in field.subfields"
                :key="index2"
              >
                <div v-if="sub_field.type == 'input'" class="col-md-6 mb-2">
                  <el-form-item :prop="sub_field.key" :label="sub_field.label">
                    <el-input
                      v-bind:id="sub_field.key"
                      v-model="model[sub_field.key]"
                      :placeholder="sub_field.placeholder"
                      :disabled="sub_field.disabled"
                      :maxlength="
                        sub_field.maxlength ? sub_field.maxlength : 255
                      "
                    >
                    </el-input>
                  </el-form-item>
                </div>
                <div
                  v-if="sub_field.type == 'textarea_limit'"
                  class="col-md-6 mb-2"
                >
                  <el-form-item :prop="sub_field.key" :label="sub_field.label">
                    <el-input
                      v-bind:id="sub_field.key"
                      v-model="model[sub_field.key]"
                      type="textarea"
                      :rows="sub_field.row"
                      :name="sub_field.key"
                      :placeholder="sub_field.placeholder"
                      :disabled="sub_field.disabled"
                      :maxlength="
                        sub_field.maxlength ? sub_field.maxlength : 255
                      "
                    >
                    </el-input>
                  </el-form-item>
                </div>
                <div v-if="sub_field.type == 'textarea'" class="col-md-6 mb-2">
                  <el-form-item :prop="sub_field.key" :label="sub_field.label">
                    <el-input
                      v-bind:id="sub_field.key"
                      v-model="model[sub_field.key]"
                      type="textarea"
                      :rows="sub_field.row"
                      :name="sub_field.key"
                      :placeholder="sub_field.placeholder"
                      :disabled="sub_field.disabled"
                    >
                    </el-input>
                  </el-form-item>
                </div>
                <div v-if="sub_field.type == 'duedate'" class="col-md-6 mb-2">
                  <el-form-item :prop="sub_field.key" :label="sub_field.label">
                    <el-date-picker
                      v-model="model[sub_field.key]"
                      :placeholder="sub_field.placeholder"
                      :name="'dueDate' + sub_field.key"
                      :popper-class="'dateTime' + sub_field.key"
                    />
                  </el-form-item>
                </div>
                <template v-else-if="sub_field.type == 'hr'">
                  <hr class="mt-4 mb-4 text-muted" />
                </template>
                <div
                  v-else-if="sub_field.type == 'number'"
                  class="col-md-6 mb-2"
                >
                  <el-form-item :prop="sub_field.key" :label="sub_field.label">
                    <el-input
                      v-model="model[sub_field.key]"
                      :placeholder="sub_field.placeholder"
                      type="number"
                      :disabled="sub_field.disabled"
                      :min="sub_field.min ? sub_field.min : 0"
                      :maxlength="
                        sub_field.maxlength ? sub_field.maxlength : 255
                      "
                    >
                    </el-input>
                  </el-form-item>
                </div>
                <div
                  v-else-if="sub_field.type == 'select'"
                  class="col-md-6 mb-2"
                >
                  <el-form-item :prop="sub_field.key" :label="sub_field.label">
                    <el-select
                      v-model="model[sub_field.key]"
                      :placeholder="sub_field.placeholder"
                      filterable
                      v-bind:id="'select' + sub_field.key"
                      :loading="loadingSelect"
                      :disabled="sub_field.disabled"
                      clearable
                      :popper-class="sub_field.key + 'Select'"
                      @click="selectAttr(sub_field.key)"
                    >
                      <el-option
                        v-for="(nilai, index) in sub_field.options"
                        :key="index"
                        :label="nilai.label"
                        :value="nilai.value"
                      />
                    </el-select>
                  </el-form-item>
                </div>
                <div
                  v-else-if="sub_field.type == 'select_tags'"
                  class="col-md-6 mb-2"
                >
                  <el-form-item :prop="sub_field.key" :label="sub_field.label">
                    <el-select
                      v-model="model[sub_field.key]"
                      :placeholder="sub_field.placeholder"
                      filterable
                      multiple
                      v-bind:id="'selectTags' + sub_field.key"
                      :tag-type="sub_field.colorTag"
                      :loading="loadingSelect"
                      clearable
                      :disabled="sub_field.disabled"
                      :popper-class="sub_field.key + 'SelectTags'"
                      @click="selectTagAttr(sub_field.key)"
                    >
                      <el-option
                        v-for="(nilai, index) in sub_field.options"
                        :key="index"
                        :label="nilai.label"
                        :value="nilai.value"
                      />
                    </el-select>
                  </el-form-item>
                </div>
                <div
                  v-else-if="sub_field.type == 'custom'"
                  class="col-md-6 mb-2"
                >
                  <slot :name="sub_field.key"></slot>
                </div>
                <div
                  v-else-if="sub_field.type == 'upload_pdf'"
                  class="col-md-6 mb-2"
                >
                  <el-form-item
                    :prop="sub_field.key"
                    :label="sub_field.label"
                    align="right"
                  >
                    <div class="input-group">
                      <input
                        type="text"
                        v-bind:id="sub_field.url"
                        class="form-control"
                        v-model="model[sub_field.key]"
                        @click="$refs[sub_field.keyUrl][0].click()"
                        :disabled="sub_field.disabled"
                        :placeholder="sub_field.placeholder"
                        readonly
                      />
                      <label class="input-group-btn">
                        <span class="btn btn-dark">
                          <template v-if="sub_field.disabled">
                            <span class="fa fa-folder"></span>
                          </template>
                          <template v-else>
                            <input
                              v-bind:id="sub_field.keyUrl"
                              :ref="sub_field.keyUrl"
                              @change="
                                changeFile(
                                  $event,
                                  sub_field.key,
                                  sub_field.keyUrl
                                )
                              "
                              type="file"
                              style="display: none"
                              accept=".pdf"
                            />
                            <span class="fa fa-folder"></span>
                          </template>
                        </span>
                      </label>
                    </div>
                    <span class="text-muted"
                      >File Maksimal 10MB, format .pdf</span
                    >
                  </el-form-item>
                </div>
                <div
                  v-else-if="sub_field.type == 'upload_img'"
                  class="col-md-6 mb-2"
                >
                  <el-form-item
                    :prop="sub_field.key"
                    :label="sub_field.label"
                    align="right"
                  >
                    <div class="input-group">
                      <input
                        v-if="this.$route.params.id"
                        type="text"
                        v-bind:id="sub_field.url"
                        class="form-control"
                        @click="openFile(model[sub_field.key])"
                        :disabled="sub_field.disabled"
                        placeholder="Produk memiliki gambar"
                        readonly
                      />
                      <input
                        v-else
                        type="text"
                        v-bind:id="sub_field.url"
                        class="form-control"
                        v-model="model[sub_field.key]"
                        @click="$refs[sub_field.keyUrl][0].click()"
                        :disabled="sub_field.disabled"
                        :placeholder="sub_field.placeholder"
                        readonly
                      />
                      <label class="input-group-btn">
                        <span class="btn btn-dark">
                          <template v-if="sub_field.disabled">
                            <span class="fa fa-image"></span>
                          </template>
                          <template v-else>
                            <input
                              v-bind:id="sub_field.keyUrl"
                              :ref="sub_field.keyUrl"
                              @change="
                                changeFileImage(
                                  $event,
                                  sub_field.key,
                                  sub_field.keyUrl
                                )
                              "
                              type="file"
                              style="display: none"
                              accept=".jpeg,.png,.jpg"
                            />
                            <span class="fa fa-image"></span>
                          </template>
                        </span>
                      </label>
                    </div>
                    <template v-if="model[sub_field.key] != null">
                      <a
                        v-if="this.$route.params.id"
                        href="javascript:void(0)"
                        @click="openFile(model[sub_field.key])"
                        style="position: absolute; left: 0"
                        >Lihat Gambar</a
                      >
                    </template>
                    <span class="text-muted"
                      >File Maksimal 10MB, format .jpeg,.png,.jpg</span
                    >
                  </el-form-item>
                </div>
                <div
                  v-else-if="sub_field.type == 'upload'"
                  class="col-md-6 mb-2"
                >
                  <el-form-item
                    :prop="sub_field.key"
                    :label="sub_field.label"
                    align="right"
                  >
                    <div class="input-group">
                      <input
                        type="text"
                        v-bind:id="sub_field.url"
                        class="form-control"
                        v-model="model[sub_field.key]"
                        @click="$refs[sub_field.keyUrl][0].click()"
                        :disabled="sub_field.disabled"
                        :placeholder="sub_field.placeholder"
                        readonly
                      />
                      <label class="input-group-btn">
                        <span class="btn btn-dark">
                          <template v-if="sub_field.disabled">
                            <span class="fa fa-file"></span>
                          </template>
                          <template v-else>
                            <input
                              v-bind:id="sub_field.keyUrl"
                              :ref="sub_field.keyUrl"
                              @change="
                                changeFileAll(
                                  $event,
                                  sub_field.key,
                                  sub_field.keyUrl
                                )
                              "
                              type="file"
                              style="display: none"
                            />
                            <span class="fa fa-file"></span>
                          </template>
                        </span>
                      </label>
                    </div>
                    <span class="text-muted">File Maksimal 10MB</span>
                  </el-form-item>
                </div>
                <div v-if="sub_field.type == 'switch'" class="col-md-6 mb-2">
                  <el-form-item :prop="sub_field.key" :label="sub_field.label">
                    <label
                      class="form-check form-switch form-check-custom form-check-solid"
                      :prop="sub_field.key"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        :true-value="true"
                        :false-value="false"
                        v-model="model[sub_field.key]"
                        v-bind:id="sub_field.key"
                        :disabled="sub_field.disabled"
                      />
                      <span class="form-check-label fw-bold"> </span>
                    </label>
                  </el-form-item>
                </div>
                <div v-if="sub_field.type == 'radio'" class="col-md-6 mb-2">
                  <el-form-item :prop="sub_field.key" :label="sub_field.label">
                    <div class="d-flex align-items-center">
                      <label
                        class="form-check form-check-custom form-check-solid me-10"
                        v-for="(data, index) in sub_field.options"
                        :key="index"
                      >
                        <input
                          v-bind:id="sub_field.key + data.label + (index + 1)"
                          class="form-check-input h-20px w-20px"
                          type="radio"
                          :value="data.value"
                          :disabled="sub_field.disabled"
                          v-model="radios[sub_field.key]"
                          @change="handleInputRadio($event, sub_field.key)"
                        />
                        <span class="form-check-label fw-bold">
                          {{ data.label }}
                        </span>
                      </label>
                    </div>
                  </el-form-item>
                </div>

                <div v-if="sub_field.type == 'checkbox'" class="col-md-6 mb-2">
                  <el-form-item :prop="sub_field.key">
                    <div class="d-flex flex-stack mb-8">
                      <div class="me-5 d-flex flex-column">
                        <label class="fs-6">
                          {{ sub_field.label }}
                          <span
                            v-if="
                              sub_field.required != undefined
                                ? sub_field.required
                                : false
                            "
                            class="required"
                          ></span>
                        </label>
                        <span class="fs-7 text-muted">{{
                          sub_field.label2
                        }}</span>
                      </div>
                      <div class="d-flex align-items-center">
                        <label
                          class="form-check form-check-custom form-check-solid me-10"
                        >
                          <input
                            class="form-check-input h-20px w-20px"
                            type="checkbox"
                            v-bind:id="sub_field.key"
                            :true-value="sub_field.true_value"
                            :false-value="sub_field.false_value"
                            v-model="model[sub_field.key]"
                          />
                          <label
                            class="form-check-label fw-bold"
                            v-bind:for="sub_field.key"
                            >{{ sub_field.data_value }}</label
                          >
                          &nbsp;
                        </label>
                      </div>
                    </div>
                  </el-form-item>
                </div>
                <div
                  v-if="sub_field.type == 'multi_checkbox'"
                  class="col-md-6 mb-2"
                >
                  <el-form-item :prop="sub_field.key">
                    <div class="d-flex flex-stack mb-8">
                      <div class="me-5 d-flex flex-column">
                        <label class="fs-6">
                          {{ sub_field.label }}
                          <span
                            v-if="
                              sub_field.required != undefined
                                ? sub_field.required
                                : false
                            "
                            class="required"
                          ></span>
                        </label>
                        <span class="fs-7 text-muted">{{
                          sub_field.label2
                        }}</span>
                      </div>
                      <div class="d-flex align-items-center">
                        <label
                          class="form-check form-check-custom form-check-solid me-10"
                          prop="selected"
                          v-for="(data, index3) in sub_field.options"
                          :key="index3"
                        >
                          <input
                            class="form-check-input h-20px w-20px"
                            type="checkbox"
                            v-bind:id="
                              'multiCheckbox' + sub_field.key + (index3 + 1)
                            "
                            :value="data.value"
                            v-model="model[sub_field.key]"
                          />
                          <span class="form-check-label fw-bold">
                            {{ data.label }}
                          </span>
                        </label>
                      </div>
                    </div>
                  </el-form-item>
                </div>
                <div
                  v-if="sub_field.type == 'num_rupiah'"
                  class="col-md-6 mb-2"
                >
                  <el-form-item :prop="sub_field.key" :label="sub_field.label">
                    <div class="input-group mb-5">
                      <span class="input-group-text" id="basic-addon1"
                        >Rp.</span
                      >
                      <input
                        type="number"
                        class="form-control"
                        :placeholder="sub_field.placeholder"
                        :disabled="sub_field.disabled"
                      />
                    </div>
                  </el-form-item>
                </div>
              </template>
            </div>
          </template>
          <template v-else>
            <div v-if="field.type == 'input'" class="mb-2">
              <el-form-item :prop="field.key" :label="field.label">
                <el-input
                  v-bind:id="field.key"
                  v-model="model[field.key]"
                  :placeholder="field.placeholder"
                  :disabled="field.disabled"
                  :maxlength="field.maxlength ? field.maxlength : 255"
                >
                </el-input>
              </el-form-item>
            </div>
            <div v-if="field.type == 'textarea'" class="mb-2">
              <el-form-item :prop="field.key" :label="field.label">
                <el-input
                  v-bind:id="field.key"
                  v-model="model[field.key]"
                  type="textarea"
                  :rows="field.row"
                  :name="field.key"
                  :placeholder="field.placeholder"
                  :disabled="field.disabled"
                >
                </el-input>
              </el-form-item>
            </div>
            <div v-if="field.type == 'textarea_limit'" class="mb-2">
              <el-form-item :prop="field.key" :label="field.label">
                <el-input
                  v-bind:id="field.key"
                  v-model="model[field.key]"
                  type="textarea"
                  :rows="field.row"
                  :name="field.key"
                  :placeholder="field.placeholder"
                  :disabled="field.disabled"
                  :maxlength="field.maxlength ? field.maxlength : 255"
                >
                </el-input>
              </el-form-item>
            </div>
            <div v-if="field.type == 'duedate'" class="mb-2">
              <el-form-item :prop="field.key" :label="field.label">
                <el-date-picker
                  v-model="model[field.key]"
                  :placeholder="field.placeholder"
                  :name="'dueDate' + field.key"
                  :popper-class="'dateTime' + field.key"
                />
              </el-form-item>
            </div>
            <template v-else-if="field.type == 'hr'">
              <hr class="mt-4 mb-4 text-muted" />
            </template>
            <div v-else-if="field.type == 'number'" class="mb-2">
              <el-form-item :prop="field.key" :label="field.label">
                <el-input
                  v-model="model[field.key]"
                  :placeholder="field.placeholder"
                  type="number"
                  :min="field.min ? field.min : 0"
                  :disabled="field.disabled"
                  :maxlength="field.maxlength ? field.maxlength : 255"
                >
                </el-input>
              </el-form-item>
            </div>
            <div v-else-if="field.type == 'select'" class="mb-2">
              <el-form-item :prop="field.key" :label="field.label">
                <el-select
                  v-model="model[field.key]"
                  :placeholder="field.placeholder"
                  filterable
                  v-bind:id="'select' + field.key"
                  :loading="loadingSelect"
                  :disabled="field.disabled"
                  clearable
                  :popper-class="field.key + 'Select'"
                  @click="selectAttr(field.key)"
                >
                  <el-option
                    v-for="(nilai, index) in field.options"
                    :key="index"
                    :label="nilai.label"
                    :value="nilai.value"
                  />
                </el-select>
              </el-form-item>
            </div>
            <div v-else-if="field.type == 'select_tags'" class="mb-2">
              <el-form-item :prop="field.key" :label="field.label">
                <el-select
                  v-model="model[field.key]"
                  :placeholder="field.placeholder"
                  filterable
                  multiple
                  v-bind:id="'selectTags' + field.key"
                  :tag-type="field.colorTag"
                  :loading="loadingSelect"
                  clearable
                  :disabled="field.disabled"
                  :popper-class="field.key + 'SelectTags'"
                  @click="selectTagAttr(field.key)"
                >
                  <el-option
                    v-for="(nilai, index) in field.options"
                    :key="index"
                    :label="nilai.label"
                    :value="nilai.value"
                  />
                </el-select>
              </el-form-item>
            </div>
            <div v-else-if="field.type == 'custom'" class="mb-2">
              <slot :name="field.key"></slot>
            </div>
            <div v-else-if="field.type == 'upload_pdf'" class="mb-2">
              <el-form-item
                :prop="field.key"
                :label="field.label"
                align="right"
              >
                <div class="input-group">
                  <input
                    type="text"
                    v-bind:id="field.url"
                    class="form-control"
                    v-model="model[field.key]"
                    @click="$refs[field.keyUrl][0].click()"
                    :disabled="field.disabled"
                    :placeholder="field.placeholder"
                    readonly
                  />
                  <label class="input-group-btn">
                    <span class="btn btn-dark">
                      <template v-if="field.disabled">
                        <span class="fa fa-folder"></span>
                      </template>
                      <template v-else>
                        <input
                          v-bind:id="field.keyUrl"
                          :ref="field.keyUrl"
                          @change="changeFile($event, field.key, field.keyUrl)"
                          type="file"
                          style="display: none"
                          accept=".pdf"
                        />
                        <span class="fa fa-folder"></span>
                      </template>
                    </span>
                  </label>
                </div>
                <span class="text-muted">File Maksimal 10MB, format .pdf</span>
              </el-form-item>
            </div>
            <div v-else-if="field.type == 'upload_img'" class="mb-2">
              <el-form-item
                :prop="field.key"
                :label="field.label"
                align="right"
              >
                <div class="input-group">
                  <input
                    type="text"
                    v-bind:id="field.url"
                    class="form-control"
                    v-model="model[field.key]"
                    @click="$refs[field.keyUrl][0].click()"
                    :disabled="field.disabled"
                    :placeholder="field.placeholder"
                    readonly
                  />
                  <label class="input-group-btn">
                    <span class="btn btn-dark">
                      <template v-if="field.disabled">
                        <span class="fa fa-image"></span>
                      </template>
                      <template v-else>
                        <input
                          v-bind:id="field.keyUrl"
                          :ref="field.keyUrl"
                          @change="
                            changeFileImage($event, field.key, field.keyUrl)
                          "
                          type="file"
                          style="display: none"
                          accept=".jpeg,.png,.jpg"
                        />
                        <span class="fa fa-image"></span>
                      </template>
                    </span>
                  </label>
                </div>
                <template v-if="model[sub_field.key] != null">
                  <a
                    href="javascript:void(0)"
                    @click="openFile(model[sub_field.key])"
                    style="position: absolute; left: 0"
                    >Lihat Gambar</a
                  >
                </template>
                <span class="text-muted"
                  >File Maksimal 10MB, format .jpeg,.png,.jpg</span
                >
              </el-form-item>
            </div>
            <div v-else-if="field.type == 'upload'" class="mb-2">
              <el-form-item
                :prop="field.key"
                :label="field.label"
                align="right"
              >
                <div class="input-group">
                  <input
                    type="text"
                    v-bind:id="field.url"
                    class="form-control"
                    v-model="model[field.key]"
                    @click="$refs[field.keyUrl][0].click()"
                    :disabled="field.disabled"
                    :placeholder="field.placeholder"
                    readonly
                  />
                  <label class="input-group-btn">
                    <span class="btn btn-dark">
                      <template v-if="field.disabled">
                        <span class="fa fa-file"></span>
                      </template>
                      <template v-else>
                        <input
                          v-bind:id="field.keyUrl"
                          :ref="field.keyUrl"
                          @change="
                            changeFileAll($event, field.key, field.keyUrl)
                          "
                          type="file"
                          style="display: none"
                        />
                        <span class="fa fa-file"></span>
                      </template>
                    </span>
                  </label>
                </div>
                <span class="text-muted">File Maksimal 10MB</span>
              </el-form-item>
            </div>
            <div v-if="field.type == 'switch'" class="mb-2">
              <el-form-item :prop="field.key">
                <div class="d-flex flex-stack mb-8">
                  <div class="me-5 d-flex flex-column">
                    <label class="fs-6">
                      {{ field.label }}
                      <span
                        v-if="
                          field.required != undefined ? field.required : false
                        "
                        class="required"
                      ></span>
                    </label>
                    <span class="fs-7 text-muted">{{ field.label2 }}</span>
                  </div>
                  <div class="d-flex align-items-center">
                    <label
                      class="form-check form-switch form-check-custom form-check-solid me-10"
                      :prop="field.key"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        :true-value="true"
                        :false-value="false"
                        v-model="model[field.key]"
                        v-bind:id="field.key"
                        :disabled="field.disabled"
                      />
                      <span class="form-check-label fw-bold"> </span>
                    </label>
                  </div>
                </div>
              </el-form-item>
            </div>
            <div v-if="field.type == 'radio'" class="mb-2">
              <el-form-item :prop="field.key">
                <div class="d-flex flex-stack mb-8">
                  <div class="me-5 d-flex flex-column">
                    <label class="fs-6">
                      {{ field.label }}
                      <span
                        v-if="
                          field.required != undefined ? field.required : false
                        "
                        class="required"
                      ></span>
                    </label>
                    <span class="fs-7 text-muted">{{ field.label2 }}</span>
                  </div>
                  <div class="d-flex align-items-center">
                    <label
                      class="form-check form-check-custom form-check-solid me-10"
                      v-for="(data, index3) in field.options"
                      :key="index3"
                    >
                      <input
                        v-bind:id="field.key + data.label + (index3 + 1)"
                        class="form-check-input h-20px w-20px"
                        type="radio"
                        :value="data.value"
                        :disabled="field.disabled"
                        v-model="radios[field.key]"
                        @change="handleInputRadio($event, field.key)"
                      />
                      <span class="form-check-label fw-bold">
                        {{ data.label }}
                      </span>
                    </label>
                  </div>
                </div>
              </el-form-item>
            </div>
            <div v-if="field.type == 'checkbox'" class="mb-2">
              <el-form-item :prop="field.key">
                <div class="d-flex flex-stack mb-8">
                  <div class="me-5 d-flex flex-column">
                    <label class="fs-6">
                      {{ field.label }}
                      <span
                        v-if="
                          field.required != undefined ? field.required : false
                        "
                        class="required"
                      ></span>
                    </label>
                    <span class="fs-7 text-muted">{{ field.label2 }}</span>
                  </div>
                  <div class="d-flex align-items-center">
                    <label
                      class="form-check form-check-custom form-check-solid me-10"
                    >
                      <input
                        class="form-check-input h-20px w-20px"
                        type="checkbox"
                        v-bind:id="field.key"
                        :true-value="field.true_value"
                        :false-value="field.false_value"
                        v-model="model[field.key]"
                      />
                      <label
                        class="form-check-label fw-bold"
                        v-bind:for="field.key"
                        >{{ field.data_value }}</label
                      >
                      &nbsp;
                    </label>
                  </div>
                </div>
              </el-form-item>
            </div>
            <div v-if="field.type == 'multi_checkbox'" class="mb-2">
              <el-form-item :prop="field.key">
                <div class="d-flex flex-stack mb-8">
                  <div class="me-5 d-flex flex-column">
                    <label class="fs-6">
                      {{ field.label }}
                      <span
                        v-if="
                          field.required != undefined ? field.required : false
                        "
                        class="required"
                      ></span>
                    </label>
                    <span class="fs-7 text-muted">{{ field.label2 }}</span>
                  </div>
                  <div class="d-flex align-items-center">
                    <label
                      class="form-check form-check-custom form-check-solid me-10"
                      prop="selected"
                      v-for="(data, index3) in field.options"
                      :key="index3"
                    >
                      <input
                        class="form-check-input h-20px w-20px"
                        type="checkbox"
                        v-bind:id="'multiCheckbox' + field.key + (index3 + 1)"
                        :value="data.value"
                        v-model="model[field.key]"
                      />
                      <span class="form-check-label fw-bold">
                        {{ data.label }}
                      </span>
                    </label>
                  </div>
                </div>
              </el-form-item>
            </div>

            <div v-if="field.type == 'num_rupiah'" class="mb-2">
              <el-form-item :prop="field.key" :label="field.label">
                <div class="input-group mb-5">
                  <span class="input-group-text" id="basic-addon1">Rp.</span>
                  <input
                    type="number"
                    min="0"
                    class="form-control"
                    :placeholder="field.placeholder"
                    :disabled="field.disabled"
                  />
                </div>
              </el-form-item>
            </div>
          </template>
        </template>
        <div class="mt-12" align="right">
          <button
            :data-kt-indicator="loading ? 'on' : null"
            class="btn btn-dark me-3"
            type="submit"
            id="btnSimpan"
            ref="btnSimpan"
          >
            <span v-if="!loading" class="indicator-label">
              Simpan
              <span class="svg-icon svg-icon-3 ms-2 me-0">
                <inline-svg src="icons/duotune/arrows/arr064.svg" />
              </span>
            </span>
            <span v-if="loading" class="indicator-progress">
              Please wait...
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>
          </button>
          <button
            type="reset"
            id="btnKembali"
            class="btn btn-secondary me-3"
            @click="btnBack"
          >
            Kembali
          </button>
        </div>
      </el-form>
    </div>
  </div>
</template>
<style lang="scss">
.el-select {
  width: 100%;
}
.el-date-editor.el-input {
  width: 100%;
}
</style>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Actions } from "@/store/enums/StoreEnums";
import { object } from "yup";
export default {
  props: {
    title: { type: String, default: "" },
    nameroute: { type: String, default: "" },
    posturl: { type: String, default: "" },
    updateurl: { type: String, default: "" },
    sendout: { type: Boolean, default: false },
    updatepost: { type: Boolean, default: false },
    title: { type: String, default: "" },
    fields: { type: Object, default: {} },
    datamodel: { type: Object, default: {} },
  },
  emits: ["submitInChildParent"],
  data() {
    return {
      loading: false,
      loadingSelect: false,
      model: {},
      rules: {},
      radios: {},
    };
  },
  beforeMount() {
    this.fetchModel();
    this.createRule();
  },
  mounted() {
    this.$store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
    for (var i in this.fields.list) {
      let rows = this.fields.list[i];
      if (rows.type == "row") {
        let subfields = rows.subfields;
        for (var j in subfields) {
          let rows2 = subfields[j];
          if (rows2.type == "duedate") {
            document
              .getElementsByName("dueDate" + rows2.key)[0]
              .addEventListener("click", function () {
                addDateId(rows2.key);
              });
          }
        }
      } else {
        if (rows.type == "duedate") {
          document
            .getElementsByName("dueDate" + rows.key)[0]
            .addEventListener("click", function () {
              addDateId(rows.key);
            });
        }
      }
    }

    function addDateId(key) {
      document
        .getElementsByName("dueDate" + key)[0]
        .setAttribute("id", "dueDateInput" + key);
      document
        .getElementsByClassName("dateTime" + key)[0]
        .setAttribute("id", "dueDateInput" + key);
    }
    setTimeout(() => {
      this.$store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
    }, 500);
  },
  methods: {
    goBack() {
      return this.$router.go(-1);
    },
    submit() {
      let newPayload = {};
      if (!this.$refs.formRef) {
        return;
      }
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$refs.btnSimpan.setAttribute("data-kt-indicator", "on");
          this.$refs.btnSimpan.disabled = true;
          // if(this.model['details']){
          //     if(this.model['details'].length == 0){
          //         Swal.fire({
          //             title: "Gagal",
          //             text: 'Data table tidak boleh kosong',
          //             icon: "error",
          //             buttonsStyling: false,
          //             confirmButtonText: "Oke",
          //             customClass: {
          //             confirmButton: "btn btn-primary",
          //             },
          //         }).then(() => {
          //             this.loading = false
          //             this.$refs.btnSimpan.removeAttribute("data-kt-indicator");
          //             this.$refs.btnSimpan.disabled = false;
          //             return false
          //         })
          //     }else{
          //         newPayload['details'] = this.model['details']
          //     }
          // }
          let fields = this.fields.list;
          let newFields = [];
          let fieldsAll = this.fields.list;
          for (var i in fieldsAll) {
            let row = fieldsAll[i];
            if (row.type == "row") {
              let subfields = row.subfields;
              for (var j in subfields) {
                let rows2 = subfields[j];
                newFields.push({ ...rows2 });
              }
            } else {
              newFields.push({ ...row });
            }
          }
          if (Object.keys(this.datamodel).length > 0) {
            for (var i in Object.keys(this.datamodel)) {
              let v_key = Object.keys(this.datamodel)[i];
              let fieldA = newFields;
              let fFields = fieldA.filter((data) => data.key === v_key);
              if (fFields.length > 0) {
                if (fFields[0].notRequest != true) {
                  newPayload[v_key] = this.datamodel[v_key];
                }
              } else {
                newPayload[v_key] = this.datamodel[v_key];
              }
            }
          } else {
            for (var i in fields) {
              let rows = fields[i];
              if (rows.type == "row") {
                let subfields = rows.subfields;
                for (var j in subfields) {
                  let rows2 = subfields[j];
                  if (rows2.type != "hr") {
                    if (rows2.notRequest != true) {
                      if (this.model[rows2.key] != undefined) {
                        if (rows2.type == "number") {
                          newPayload[rows2.key] = Number(this.model[rows2.key]);
                        } else {
                          newPayload[rows2.key] = this.model[rows2.key];
                        }
                      }
                    }
                  }
                }
              } else {
                if (rows.type != "hr") {
                  if (rows.notRequest != true) {
                    if (this.model[rows.key] != undefined) {
                      if (rows.type == "number") {
                        newPayload[rows.key] = Number(this.model[rows.key]);
                      } else {
                        newPayload[rows.key] = this.model[rows.key];
                      }
                    }
                  }
                }
              }
            }
          }
          // this.loading = false
          // this.$refs.btnSimpan.removeAttribute("data-kt-indicator");
          // this.$refs.btnSimpan.disabled = false;
          // console.log(newPayload)
          // return false
          setTimeout(() => {
            if (this.sendout) {
              this.loading = false;
              this.sendToParent();
              this.$refs.btnSimpan.removeAttribute("data-kt-indicator");
              this.$refs.btnSimpan.disabled = false;
              return false;
            }
            if (this.$route.params.id == null) {
              this.$apiservice.setHeader();
              this.$apiservice
                .post(this.posturl, newPayload)
                .then((res) => {
                  if (res.data.error) {
                    Swal.fire({
                      title: "Gagal",
                      text: res.data.message,
                      icon: "error",
                      buttonsStyling: false,
                      confirmButtonText: "Oke",
                      customClass: {
                        confirmButton: "btn btn-primary",
                      },
                    }).then(() => {
                      this.loading = false;
                      this.$refs.btnSimpan.removeAttribute("data-kt-indicator");
                      this.$refs.btnSimpan.disabled = false;
                    });
                  } else {
                    Swal.fire({
                      title: "Berhasil ditambah",
                      text: "Anda telah berhasil menambahkan data baru",
                      icon: "success",
                      buttonsStyling: false,
                      confirmButtonText: "Ok, got it!",
                      customClass: {
                        confirmButton: "btn btn-primary",
                      },
                    }).then(() => {
                      this.$router.push({ name: this.nameroute });
                    });
                  }
                })
                .catch((err) => {
                  if (err.response.status == 401) {
                    this.$router.push({ name: "401" });
                  } else if (err.response.status == 404) {
                    this.$router.push({ name: "404" });
                  } else if (err.response.status == 500) {
                    this.$router.push({ name: "500" });
                  } else {
                    if (err.response.data.message) {
                      Swal.fire({
                        title: "Gagal",
                        text: err.response.data.message,
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "Oke",
                        customClass: {
                          confirmButton: "btn btn-primary",
                        },
                      }).then(() => {
                        this.loading = false;
                        this.$refs.btnSimpan.removeAttribute(
                          "data-kt-indicator"
                        );
                        this.$refs.btnSimpan.disabled = false;
                      });
                    }
                  }
                })
                .finally(() => {
                  this.loading = false;
                  this.$refs.btnSimpan.removeAttribute("data-kt-indicator");
                  this.$refs.btnSimpan.disabled = false;
                });
            } else {
              this.$apiservice.setHeader();
              let urlink = null;
              if (this.updatepost) {
                urlink = this.$apiservice.post;
              } else {
                urlink = this.$apiservice.put;
              }
              urlink(this.updateurl, newPayload)
                .then((res) => {
                  if (res.data.error) {
                    Swal.fire({
                      title: "Gagal",
                      text: res.data.message,
                      icon: "error",
                      buttonsStyling: false,
                      confirmButtonText: "Oke",
                      customClass: {
                        confirmButton: "btn btn-primary",
                      },
                    }).then(() => {
                      this.loading = false;
                      this.$refs.btnSimpan.removeAttribute("data-kt-indicator");
                      this.$refs.btnSimpan.disabled = false;
                    });
                  } else {
                    Swal.fire({
                      title: "Berhasil diperbarui",
                      text: "Anda telah berhasil memperbarui data baru",
                      icon: "success",
                      buttonsStyling: false,
                      confirmButtonText: "Oke",
                      customClass: {
                        confirmButton: "btn btn-primary",
                      },
                    }).then(() => {
                      this.$router.push({ name: this.nameroute });
                    });
                  }
                })
                .catch((err) => {
                  if (err.response.status == 401) {
                    this.$router.push({ name: "401" });
                  } else if (err.response.status == 404) {
                    this.$router.push({ name: "404" });
                  } else if (err.response.status == 500) {
                    this.$router.push({ name: "500" });
                  } else {
                    if (err.response.data.message) {
                      Swal.fire({
                        title: "Gagal",
                        text: err.response.data.message,
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "Oke",
                        customClass: {
                          confirmButton: "btn btn-primary",
                        },
                      }).then(() => {
                        this.loading = false;
                        this.$refs.btnSimpan.removeAttribute(
                          "data-kt-indicator"
                        );
                        this.$refs.btnSimpan.disabled = false;
                      });
                    }
                  }
                })
                .finally(() => {
                  this.loading = false;
                  this.$refs.btnSimpan.removeAttribute("data-kt-indicator");
                  this.$refs.btnSimpan.disabled = false;
                });
            }
          }, 1000);
        } else {
          Swal.fire({
            text: "Data yang anda masukkan gagal ditambahkan",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Oke",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          }).then(() => {
            this.loading = false;
            this.$refs.btnSimpan.removeAttribute("data-kt-indicator");
            this.$refs.btnSimpan.disabled = false;
          });
        }
      });
    },
    switchChange(e) {},
    sendToParent() {
      this.$emit("submitInChildParent", this.model);
    },
    hideLoading() {
      this.$refs.btnSimpan.removeAttribute("data-kt-indicator");
      this.$refs.btnSimpan.disabled = false;
    },
    selectAttr(key) {
      document
        .getElementsByClassName(key + "Select")[0]
        .setAttribute("id", "selectList" + key);
    },
    selectTagAttr(key) {
      document
        .getElementsByClassName(key + "SelectTags")[0]
        .setAttribute("id", "selectTagsList" + key);
    },
    handleInputRadio(val, key) {
      this.model[key] = val;
    },
    changeFile(val, key, keyurl) {
      let files = val.target.files[0];
      if (files.type == "application/pdf") {
        if (files.size > 10000000) {
          Swal.fire({
            text: "Gagal, ukuran file maksimal 10MB",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Oke",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        } else {
          this.model[key] = files.name;
          this.createBase64url(files, keyurl);
        }
      } else {
        Swal.fire({
          text: "Gagal, format file harus .pdf",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Oke",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      }
    },
    changeFileImage(val, key, keyurl) {
      let files = val.target.files[0];
      if (
        files.type == "image/jpeg" ||
        files.type == "image/png" ||
        files.type == "image/jpg"
      ) {
        if (files.size > 10000000) {
          Swal.fire({
            text: "Gagal, ukuran file maksimal 10MB",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Oke",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        } else {
          this.model[key] = files.name;
          this.createBase64url(files, keyurl);
        }
      } else {
        Swal.fire({
          text: "Gagal, format file harus .jpg, .png, .jpeg",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Oke",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      }
    },
    openFile(link) {
      this.$apiservice.setHeader();
      this.$apiservice
        .query("/reference/v1/get-file", { params: { Url: link } })
        .then((res) => {
          window.open(res.request.responseURL, "_blank");
        })
        .catch((err) => {
          if (err) {
            Swal.fire({
              text: "File Tidak Ditemukan Di Server",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Oke",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          }
        });
    },
    changeFileAll(val, key, keyurl) {
      let files = val.target.files[0];
      if (files.size > 10000000) {
        Swal.fire({
          text: "Gagal, ukuran file maksimal 10MB",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Oke",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      } else {
        this.model[key] = files.name;
        this.createBase64url(files, keyurl);
      }
    },
    createBase64url(files, keyurl) {
      var reader = new FileReader();
      reader.onload = function (e) {
        this.model[keyurl] = e.target.result;
        // this.model[keyurl] = e.target.result.replace('data:', '').replace(/^.+,/, '')
      }.bind(this);
      reader.onerror = function (error) {
        alert(error);
      };
      reader.readAsDataURL(files);
    },
    async fetchModel() {
      let newParam = {};
      let fields = this.fields.list;
      for (var i in fields) {
        let rows = fields[i];
        if (rows.type == "row") {
          let subfields = rows.subfields;
          for (var j in subfields) {
            let rows2 = subfields[j];
            if (rows2.type == "select_tags" || rows2.type == "multi_checkbox") {
              let val =
                this.model[rows2.key] == undefined ? [] : this.model[rows2.key];
              newParam[rows2.key] = val;
            } else if (rows2.type == "radio") {
              newParam[rows2.key] = rows2.default;
              this.radios[rows2.key] = rows2.default;
            } else if (rows2.type == "switch") {
              newParam[rows2.key] = rows2.default;
            } else if (rows2.type == "hr") {
            } else if (rows2.type == "row") {
            } else if (rows2.type == "custom") {
              if (rows2.multiple) {
                newParam[rows2.key] = [];
              }
            } else {
              let val =
                this.model[rows2.key] == undefined ? "" : this.model[rows2.key];
              newParam[rows2.key] = val;
            }
          }
        } else {
          if (rows.type == "select_tags" || rows.type == "multi_checkbox") {
            let val =
              this.model[rows.key] == undefined ? [] : this.model[rows.key];
            newParam[rows.key] = val;
          } else if (rows.type == "radio") {
            newParam[rows.key] = rows.default;
            this.radios[rows.key] = rows.default;
          } else if (rows.type == "switch") {
            newParam[rows.key] = rows.default;
          } else if (rows.type == "hr") {
          } else if (rows.type == "row") {
          } else if (rows.type == "custom") {
            if (rows.multiple) {
              newParam[rows.key] = [];
            }
          } else {
            let val =
              this.model[rows.key] == undefined ? "" : this.model[rows.key];
            newParam[rows.key] = val;
          }
        }
      }
      if (Object.keys(this.datamodel).length > 0) {
        this.model = this.datamodel;
      } else {
        this.model = newParam;
      }
    },
    createRule() {
      this.rules = {};
      let fields = this.fields.list;
      for (var i in fields) {
        let rows = fields[i];
        if (rows.type == "row") {
          let subfields = rows.subfields;
          for (var j in subfields) {
            let rows2 = subfields[j];
            if (rows2.type == "select_tags") {
              this.rules[rows2.key] = [
                {
                  type: "array",
                  required: rows2.required ? rows2.required : false,
                  message: rows2.label + " wajib di isi",
                  trigger: "change",
                },
              ];
            } else if (rows2.type == "duedate") {
              this.rules[rows2.key] = [
                {
                  type: "date",
                  required: rows2.required ? rows2.required : false,
                  message: rows2.label + " wajib di isi",
                  trigger: "change",
                },
              ];
            } else if (rows2.type == "custom") {
              this.rules[rows2.key] = [
                {
                  required: rows2.required ? rows2.required : false,
                  message: rows2.label + " wajib di isi",
                  trigger: "change",
                },
              ];
            } else if (rows2.type == "hr") {
            } else if (rows2.type == "row") {
            } else {
              this.rules[rows2.key] = [
                {
                  required: rows2.required ? rows2.required : false,
                  message: rows2.label + " wajib di isi",
                  trigger: "change",
                },
              ];
            }
          }
        } else {
          if (rows.type == "select_tags") {
            this.rules[rows.key] = [
              {
                type: "array",
                required: rows.required ? rows.required : false,
                message: rows.label + " wajib di isi",
                trigger: "change",
              },
            ];
          } else if (rows.type == "duedate") {
            this.rules[rows.key] = [
              {
                type: "date",
                required: rows.required ? rows.required : false,
                message: rows.label + " wajib di isi",
                trigger: "change",
              },
            ];
          } else if (rows.type == "custom") {
            this.rules[rows.key] = [
              {
                required: rows.required ? rows.required : false,
                message: rows.label + " wajib di isi",
                trigger: "change",
              },
            ];
          } else if (rows.type == "hr") {
          } else if (rows.type == "row") {
          } else {
            this.rules[rows.key] = [
              {
                required: rows.required ? rows.required : false,
                message: rows.label + " wajib di isi",
                trigger: "change",
              },
            ];
          }
        }
      }
    },
    btnBack() {
      this.$router.go(-1);
    },
  },
};
</script>
