<template>

    <baseform v-if="refreshForm" :title="title" :fields="fields" :sendout="false" :nameroute="nameroute"
        :datamodel="model" :posturl="posturl" :updateurl="updateurl" :updatepost="updatepost">
        <template v-slot:po_code>
            <el-form-item prop="po_code" label="Nomor Purchase Order">
                <el-input v-bind:id="'InputIDPOCode'" v-model="model.po_code" :disabled="true">
                </el-input>
            </el-form-item>
        </template>
        <template v-slot:vendor_id>
            <el-form-item prop="vendor_id" label="Nama Vendor Produksi">
                <el-select @click="selectVendor" v-model="model.vendor_id" filterable placeholder="Pilih Nama Vendor Produksi"
                    :remote-method="cariVendor" @change="handleSelect($event, 'vendor')" remote clearable
                    v-bind:id="'inputVendor'" :popper-class="'vendorSelect'">
                    <el-option v-for="item in lsVendor" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
            </el-form-item>
        </template>
        <template v-slot:po_type>
            <el-form-item prop="po_type" label="Tipe PO">
                <el-select v-model="model.po_type" placeholder="Pilih Tipe PO" filterable v-bind:id="'selectPoType'"
                    clearable :popper-class="'SelectLsPoType'" @click="selectAttr('LsPoType')"
                    @change="handleSelect($event, 'po_type')">
                    <el-option v-for="(nilai, index) in lsPoType" :key="index" :label="nilai.label"
                        :value="nilai.value" />
                </el-select>
            </el-form-item>
        </template>
        <template v-slot:po_code_master>
            <el-form-item prop="po_code_master" label="Reference PO Master">
                <el-select v-model="model.po_code_master" placeholder="Pilih Reference PO Master" filterable
                    v-bind:id="'selectPoReferenceType'" clearable :popper-class="'SelectLsPoReferenceType'"
                    @click="selectAttr('LsPoReferenceType')" :remote-method="cariPO" remote :disabled="dsPoMaster">
                    <el-option v-for="(nilai, index) in lsPoMaster" :key="index" :label="nilai.label"
                        :value="nilai.value" />
                </el-select>
            </el-form-item>
        </template>
        <template v-slot:delivery_vendor_id>
            <el-form-item prop="delivery_vendor_id" label="Gudang">
                <el-select @click="selectAttr('StorageSelect')" v-model="model.delivery_vendor_id" filterable
                    placeholder="Pilih Nama Gudang" :remote-method="cariVendorB" @change="handleSelect($event, 'vendor_b')" remote clearable
                    :disabled="fields.list[4].subfields[1].disabled" v-bind:id="'inputStorage'"
                    :popper-class="'selectStorageSelect'">
                    <el-option v-for="item in lsVendorB" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
            </el-form-item>
        </template>
        <template v-slot:delivery_type>
            <el-form-item prop="delivery_type" label="Pengiriman">
                <el-select v-model="model.delivery_type" placeholder="Pilih Pengiriman" filterable
                    v-bind:id="'selectDeliveryType'" clearable :popper-class="'SelectLsDeliveryType'"
                    :disabled="fields.list[4].subfields[0].disabled" @click="selectAttr('LsDeliveryType')"
                    @change="handleSelect($event, 'delivery_type')">
                    <el-option v-for="(nilai, index) in lsDelivery" :key="index" :label="nilai.label"
                        :value="nilai.value" />
                </el-select>
            </el-form-item>
        </template>
        <template v-slot:delivery_department_code>
            <el-form-item prop="delivery_department_code" label="Departemen">
                <el-select v-model="model.delivery_department_code" placeholder="Pilih Departemen Pengadaan" filterable
                    v-bind:id="'selectIdDepartemenCode'" clearable :disabled="dsDept"
                    :popper-class="'SelectIdDepartement'" @click="selectAttr('IdDepartement')"
                    @change="handleSelect($event, 'department')">
                    <el-option v-for="(nilai, index) in lsDepartment" :key="index" :label="nilai.label"
                        :value="nilai.value" />
                </el-select>
            </el-form-item>
        </template>
        <template v-slot:department_code>
            <el-form-item prop="department_code" label="Departemen Pengadaan">
                <el-select v-model="model.department_code" placeholder="Pilih Departemen Pengadaan" filterable
                    v-bind:id="'selectIdDepartemenCode'" clearable :disabled="true"
                    :popper-class="'SelectIdDepartement'" @click="selectAttr('IdDepartement')"
                    @change="handleSelect($event, 'department')">
                    <el-option v-for="(nilai, index) in lsDepartment" :key="index" :label="nilai.label"
                        :value="nilai.value" />
                </el-select>
            </el-form-item>
        </template>
        <template v-slot:delivery_branch_id>
            <el-form-item prop="delivery_branch_id" label="Cabang">
                <el-select v-model="model.delivery_branch_id" placeholder="Pilih Cabang" filterable
                    v-bind:id="'selectIdBranch'" clearable :popper-class="'popperSelectIdBranch'"
                    @click="selectAttr('popperSelectIdBranch')" @change="handleSelect($event, 'branch')"
                    :disabled="fields.list[4].subfields[2].disabled">
                    <el-option v-for="(nilai, index) in lsBranch" :key="index" :label="nilai.label"
                        :value="nilai.value" />
                </el-select>
            </el-form-item>
        </template>
        <template v-slot:ppn>
            <el-form-item prop="ppn" label="PPn (%)">
                <el-input v-bind:id="'InputPPn'" v-model="model.ppn" type="number" placeholder="Masukkan PPn (%)"
                    @input="hitung($event)">
                </el-input>
            </el-form-item>
        </template>

        <template v-slot:discount v-if="this.model.po_type == 'STDT' && this.model.nilai_pengajuan != ''">
            <el-form-item prop="discount" label="Diskon">
                <el-input v-bind:id="'discount'" v-model="model.discount" type="number" placeholder="Masukkan Diskon"
                    min="0" @input="hitungDiskon">
                </el-input>
            </el-form-item>
        </template>
        <template v-slot:attachment_filename>
        <el-form-item prop="attachment_filename" label="Upload Dokumen" align="right">
            <div class="input-group">
            <input type="text" v-bind:id="'uploadDocsFilename'" class="form-control" v-model="attachment_filename"
                @click="$refs['path_attachment'].click()" placeholder="Upload Dokumen" readonly />
            <label class="input-group-btn">
                <span class="btn btn-dark">
                <input v-bind:id="'path_attachment'" ref="path_attachment" @change="
                    changeFile($event, 'attachment_filename', 'path_attachment')
                " type="file" style="display: none" accept=".pdf" />
                <span class="fa fa-file"></span>
                </span>
            </label>
            </div>
            <template v-if="$route.params.id">
            <template v-if="model.attachment_name != ''">
                <div align="left">
                <a href="javascript:void(0)" @click="openFile(model.path_attachment)" title="Lihat File">Lihat File</a>
                </div>
            </template>
            </template>
            <span class="text-muted">File Maksimal 10MB, format .pdf</span>
        </el-form-item>
        </template>
        <template v-slot:product>
            <div class="mb-2">
                <div class="d-flex justify-content-end mt-15">
                    <button type="button" @click="addProduct" class="btn btn-dark"><span
                            class="fa fa-plus-circle"></span> Tambah Product PR</button>
                </div>
                <Datatable v-if="refreshTable" ref="basetable" :table-header="tabelHeader" :listData="model.details">
                    <template v-slot:cell-pr_code="{ row: data }">
                        {{ data.pr_code }}
                    </template>
                    <template v-slot:cell-branch_name="{ row: data }">
                        {{ data.branch_name }}
                    </template>
                    <template v-slot:cell-departement_name="{ row: data }">
                        {{ data.departement_name }}
                    </template>
                    <template v-slot:cell-updated_date="{ row: data }">
                        {{ $mix.format_date_time(data.updated_date) }}
                    </template>
                    <template v-slot:cell-product_name="{ row: data }">
                        {{ data.product_name }}
                    </template>
                    <template v-slot:cell-product_id="{ row: data }">
                        {{ data.product_id }}
                    </template>
                    <template v-slot:cell-asset="{ row: data }">
                        {{ data.asset }}
                    </template>
                    <template v-slot:cell-lob_name="{ row: data }">
                        {{ data.lob_name }}
                    </template>
                    <template v-slot:cell-stock_on_hand="{ row: data }">
                        {{ data.stock_on_hand }}
                    </template>
                    <template v-slot:cell-unit_price="{ row: data }">
                        {{ $mix.formatCurrency(data.unit_price, 'IDR') }}
                    </template>
                    <template v-slot:cell-unit_id="{ row: data }">
                        {{ data.unit_id }}
                    </template>
                    <template v-slot:cell-sub_total_perproduk="{ row: data }">
                        {{ $mix.formatCurrency(data.sub_total_perproduk, 'IDR') }}
                    </template>
                    <template v-slot:cell-qty="{ row: data }">
                        {{ data.qty }} &nbsp;&nbsp;&nbsp; <a href="javascript:void(0)"
                            @click="btnEditStok(data.id_data)"><span class="fa fa-edit"></span></a>
                    </template>
                    <template v-slot:cell-vendor_kurir_id="{ row: data }">
                            <template v-if="data.is_enable_vendor_kurir == true">
                                {{ data.vendor_kurir_id == "" ? "-" :  data.vendor_name }} &nbsp;&nbsp;&nbsp; <a href="javascript:void(0)"
                                @click="btnEditVendorKurir(data.id_data,data.branch_id,data.product_id,data.vendor_kurir_id)"><span class="fa fa-edit"></span></a>
                            </template>
                            <template v-else>
                                -                                
                            </template>
                    </template>
                    <template v-slot:cell-action="{ row: data }">
                        <button type="button" v-bind:id="'btnHapus' + data.id_data" @click="deletePR(data.id_data)"
                            class="btn btn-sm btn-light-info"><span class="fa fa-trash"></span></button>&nbsp;
                    </template>
                </Datatable>
                <el-dialog title="Tambah Purchase Request" v-model="dialogVisibleDialog" width="80%"
                    custom-class="styleDialogProduct">
                    <div class="row mb-3">
                        <div style="flex: 0 0 auto;width: 100%;">
                            <input type="text" placeholder="&#xf002;&nbsp; Cari" v-model="searchPR" @input="cariPR"
                                id="inputCari" class="form-control for-cari" />
                        </div>
                    </div>
                    <div class="col-md-12 mb-2">
                    <div :class="loadingDialog == true ? 'row overlay overlay-block' : 'row'">
                        <template v-if="loadingDialog">
                        <div class="overlay-layer bg-opacity-5">
                            <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                        </template>
                        <template v-else>
                        <Datatable3 v-if="refreshTablePR" ref="basetablePR" :table-header="tabelHeaderPR"
                            :listData="listDataPR">

                            <template v-slot:head-choice_product>
                                <input class="form-check-input h-20px w-20px mr-2" type='checkbox' @click='checkAll()'
                                    v-model='isCheckAll'>
                                Pilih
                            </template>

                            <template v-slot:cell-choice_product="{ row: data }">
                                <input class="form-check-input h-20px w-20px" type="checkbox"
                                    v-bind:id="'multiCheckboxValuePR' + data.pr_code.substr(15, 6)" :value="data.pr_code"
                                    v-model='listValueProduct' @change='updateCheckall()' />
                                <!-- {{ data }} -->
                            </template>
                            <template v-slot:cell-pr_code="{ row: data }">
                                {{ data.pr_code }}
                            </template>
                            <template v-slot:cell-branch="{ row: data }">
                                {{ data.branch }}
                            </template>
                            <template v-slot:cell-department="{ row: data }">
                                {{ data.department }}
                            </template>
                            <template v-slot:cell-created_date="{ row: data }">
                                {{ $mix.format_date(data.created_date) }}
                            </template>
                            <template v-slot:cell-grand_total="{ row: data }">
                                {{ $mix.formatCurrency(data.grand_total, 'IDR') }}
                            </template>
                        </Datatable3>
                        </template>
                    </div>
                    </div>
                    <template v-slot:footer>
                        <span class="dialog-footer d-flex justify-content-end">
                            <!-- <button type="button" class="btn btn-dark mr-2"
                                @click="btnSave">Simpan</button> -->
                            <button type="button" class="btn btn-dark mr-2"
                            :data-kt-indicator="loading ? 'on' : null" ref="tSave" @click="btnSave" :disabled="false">
                            <span class="indicator-label"> Simpan </span>
                            <span class="indicator-progress">
                                Please wait...
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                            </button>
                                
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                
                            <button type="button" class="btn btn-light"
                                @click="dialogVisibleDialog = false">Batal</button>
                        </span>
                    </template>
                </el-dialog>
                <el-dialog title="Edit Order Qty" v-model="dialogVisibleDialogStok" width="30%"
                    custom-class="styleDialogStok">
                    <!-- <div class="d-flex justify-content-center align-items-start"> -->
                    <div class="col-md-12 mb-2">
                        <label for="">Minimal Stok</label>
                        <input type="number" v-model="orderqty.min_stock" readonly="readonly" class="form-control"
                            placeholder="Minimal Stok" />
                    </div>
                    <div class="col-md-12 mb-2">
                        <label for="">Maksimum Stok</label>
                        <input type="number" v-model="orderqty.max_stock" readonly="readonly" class="form-control"
                            placeholder="Maksimum Stok" />
                    </div>
                    <div class="col-md-12 mb-2">
                        <label for="">Order Qty</label>
                        <input type="number" v-model="orderqty.stok_val" class="form-control"
                            placeholder="Masukkan Stok" />
                        <!-- <input type="number" v-model="orderqty.stok_val" @input="checkStock" class="form-control" placeholder="Masukkan Stok" /> -->
                    </div>
                    <!-- </div> -->
                    <template v-slot:footer>
                        <span class="dialog-footer d-flex justify-content-center">
                            <button type="button" class="btn btn-dark mr-2"
                                @click="btnStokSave(tempIdStok)">Simpan</button>&nbsp;&nbsp;&nbsp;&nbsp;
                            <button type="button" class="btn btn-light"
                                @click="dialogVisibleDialogStok = false">Batal</button>
                        </span>
                    </template>
                </el-dialog>
                <el-dialog title="Edit Vendor Kurir" v-model="dialogVisibleDialogVendorKurir" width="50%"
                    custom-class="styleDialogStok">
                    <div class="col-md-12 mb-2">
                        <el-form-item prop="vendor_id" label="Nama Vendor Kurir">
                            <el-select @click="selectVendorKurir" v-model="vendor_kurir_id" filterable placeholder="Pilih Nama Vendor Kurir"
                                :remote-method="cariVendorKurir" @change="handleSelect($event, 'vendor_kurir')" remote clearable
                                v-bind:id="'inputVendorKurir'" :popper-class="'vendorSelectKurir'">
                                <el-option v-for="item in lsVendorKurir" :key="item.value" :label="item.label" :value="item.value" />
                            </el-select>
                        </el-form-item>
                    </div>
                    <template v-slot:footer>
                        <span class="dialog-footer d-flex justify-content-center">
                            <button type="button" class="btn btn-dark mr-2"
                                @click="btnSaveVendorKurir(tempIdVendorKurir.id_data)">Simpan</button>&nbsp;&nbsp;&nbsp;&nbsp;
                            <button type="button" class="btn btn-light"
                                @click="dialogVisibleDialogVendorKurir = false">Batal</button>
                        </span>
                    </template>
                </el-dialog>
            </div>
        </template>
    </baseform>
</template>
<script>
import baseform from '@/views/base/BaseFormFull.vue'
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import Datatable3 from "@/components/kt-datatable/KTDatatable3.vue";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default {
    components: {
        baseform,
        Datatable,
        Datatable3,
    },
    props: {
        title: { type: String, default: '' },
        stand: { type: String, default: 'po' },
    },
    data() {
        const list = [
            { key: 'pr_code', name: 'NOMOR MASTER PURCHASE REQUEST', sortable: true },
            { key: 'branch_name', name: 'Cabang', sortable: true },
            { key: 'departement_name', name: 'Departemen', sortable: true },
            { key: 'updated_date', name: 'TANGGAL DISETUJUI', sortable: true },
            { key: 'product_name', name: 'Nama PRODUK', sortable: true },
            { key: 'product_id', name: 'ID PRODUK', sortable: true },
            { key: 'asset', name: 'Asset - Kategori', sortable: true },
            { key: 'unit_price', name: 'Harga Satuan', sortable: true },
            { key: 'unit_id', name: 'Satuan', sortable: true },
            { key: 'stock_on_hand', name: 'Stock On Hand', sortable: true },
            { key: 'lob_name', name: 'LoB', sortable: true },
            { key: 'qty', name: 'Order Qty', sortable: true },
            { key: 'sub_total_perproduk', name: 'Total Harga', sortable: true },
            { key: 'vendor_kurir_id', name: 'Vendor Kurir', sortable: true },
            { key: 'action', name: 'Aksi', sortable: true, stickyColumn: true },
        ]
        const listPR = [
            { key: 'choice_product', name: 'Pilih', sortable: true },
            { key: 'pr_code', name: 'NOMOR PURCHASE REQUEST', sortable: true },
            { key: 'branch', name: 'CABANG', sortable: true },
            { key: 'department', name: 'Departemen', sortable: true },
            { key: 'created_date', name: 'TANGGAL PENGAJUAN', sortable: true },
            { key: 'grand_total', name: 'TOTAL HARGA', sortable: true },
        ]
        return {
            posturl: '/purchaseorder/v1/create',
            updateurl: '/purchaseorder/v1/submit-revise',
            nameroute: "procurement-master-purchase-order",
            vendor_select: '',
            vendor_option: [],
            searchPR: '',
            lsDepartment: [],
            price_per_unit: null,
            loadingSelect: false,
            loadingDialog: false,
            loading: false,
            tempId: null,
            refreshTable: true,
            refreshTablePR: true,
            dsDept: true,
            dsBranch: true,
            tabelHeader: list,
            tabelHeaderPR: listPR,
            disableAddVendor: true,
            standVendor: {},
            listData: [],
            listDataPR: [],
            lobCheck: [],
            orderqty: {
                stok_val: null,
                max_stock: null,
                min_stock: null,
            },
            vendor_kurir_id:"",
            vendor_name:"",
            updatepost: false,
            model: {
                details: [],
                nilai_pengajuan: '',
                tot_pengajuan: '',
                po_code: '',
                delivery_type: '',
                department_code: '',
                delivery_department_code: '',
                delivery_branch_id: '',
                attachment_filename: "",
                po_type: '',
                ppn: 0,
                discount: 0,
            },

            nilai_pengajuan_sementara: '',

            ppn: '',
            disableBtn: false,
            dialogVisibleDialog: false,
            dialogVisibleDialogVendorKurir: false,
            listValueProduct: [],
            attachment_filename: "",
            lsBranch: [],
            isCheckAll: false,
            lsPoMaster: [],
            lsPoType: [],
            lsDelivery: [],
            fields: {
                label_position: 'top', //right or left
                label_width: "120px",
                list: [
                    {
                        key: '1', type: 'row', grid: '2', subfields:
                            [
                                { key: 'po_code', type: 'custom' },
                                { key: 'department_code', type: 'custom', notRequest: true },
                            ]
                    },
                    {
                        key: '5', type: 'row', grid: '2', subfields:
                            [
                                { key: 'po_type', type: 'custom', options: [], required: true },
                                { key: 'po_code_master', type: 'custom', required: false },
                                // {key:'po_code_master',type:'custom',options:[],required:true},
                            ]
                    },
                    { key: 'vendor_id', type: 'custom' },
                    { key: 'vendor_type_id', type: 'select', label: 'Tipe Vendor Produksi', placeholder: 'Pilih Tipe Vendor Produksi', options: [], required: false, disabled: true },
                    {
                        key: '6', type: 'row', grid: '2', subfields:
                            [
                                { key: 'delivery_type', type: 'custom', options: [], required: true, disabled: true, label: 'Pengiriman' },
                                { key: 'delivery_vendor_id', type: 'custom', options: [], required: true, label: 'Gudang', disabled: true },
                                { key: 'delivery_branch_id', type: 'custom', options: [], required: true, label: 'Cabang', disabled: true },
                                { key: 'delivery_department_code', type: 'custom', options: [], required: true, label: 'Departemen' },
                            ]
                    },
                    {
                        key: '2', type: 'row', grid: '2', subfields:
                            [
                                { key: 'norek', type: 'number', notRequest: true, label: 'Nomor Rekening', placeholder: 'Masukkan Nomor Rekening', required: false, disabled: true, maxlength: 30 },
                                { key: 'bank_id', type: 'select', notRequest: true, label: 'Bank', placeholder: 'Pilih Bank', options: [], required: false, disabled: true },
                                { key: 'kantor_cabang_bank', notRequest: true, type: 'input', label: 'Cabang Bank', placeholder: 'Masukkan Cabang Bank', required: false, disabled: true },
                                { key: 'notelp', type: 'input', notRequest: true, label: 'Telepon', placeholder: 'Masukkan Telepon', required: false, disabled: true },
                            ]
                    },
                    { key: 'alamat', type: 'textarea', notRequest: true, label: 'Alamat', placeholder: 'Masukkan alamat', row: '4', required: false, disabled: true },
                    { key: 'reason', type: 'textarea_limit', label: 'Catatan', placeholder: 'Masukkan catatan', row: '4', required: true, disabled: false },
                    // {
                    //     key: "attachment_filename",
                    //     keyUrl: "path_attachment",
                    //     type: "upload_pdf",
                    //     label: "Unggah Dokumen",
                    //     placeholder: "Upload Dokumen",
                    //     required: false,
                    //     disabled: false,
                    // },
                    {
                        key: "attachment_filename",
                        type: "custom",
                        label: "Unggah Dokumen",
                        required: true,
                    },
                    { key: 'product', type: 'custom' },

                    {
                        key: '3', type: 'row', grid: '2', subfields:
                            [
                                { key: 'kosong2', type: 'custom', notRequest: true },
                                { key: 'nilai_pengajuan', notRequest: true, type: 'input', label: 'Nilai Pengajuan', placeholder: '', required: false, disabled: true },
                            ]
                    },

                    {
                        key: '4', type: 'row', grid: '2', subfields:
                            [
                                { key: 'kosong', notRequest: true, type: 'custom' },
                                { key: 'discount', type: 'custom' },
                            ]
                    },

                    { type: 'hr' },
                    {
                        key: '4', type: 'row', grid: '2', subfields:
                            [
                                { key: 'kosong3', type: 'custom', notRequest: true },
                                { key: 'tot_pengajuan', notRequest: true, type: 'input', label: 'Total Harga Pengajuan', placeholder: '', required: false, disabled: true },
                            ]
                    },
                ]
            },
            refreshForm: true,
            dsPoMaster: false,
            updateLob: [],
            tempIdStok: null,
            tempIdVendorKurir: {
                id_data:null,
                branch_id:null,
                product_id:null
            },
            profile: JSON.parse(this.$store.getters.currentUser),
            filter: {
                page_size: 1000,
                page_index: 1,
                sort: [],
                approval_status: 'STCPT',
                department_procurement_code: '',
            },
            lsVendor: [],
            lsVendorB: [],
            lsVendorKurir: [],
            dialogVisibleDialogStok: false,
        }
    },
    mounted() {

        this.model.department_code = this.profile.data.department_code
        this.$nextTick(() => {
            this.fetchDepartment()
        })
        this.$nextTick(() => {
            this.fetchVendor()
            this.fetchVendorB()
        })
        this.$nextTick(() => {
            // this.cariBranch()
        })
        this.$nextTick(() => {
            this.fetchVendorType()
            this.fetchPO()
            this.fetchPoType()
            this.fetchDelivery()
        })

        if (this.$route.params.id) {
            this.updateurl = '/purchaseorder/v1/submit-revise'
            this.$nextTick(() => {
                this.fetchData()
            })
        } else {
            this.fetchUserProfile()
        }
    },
    methods: {
        checkStock(val) {
            let data = val.target.value
            if (data > this.orderqty.max_stock) {
                this.orderqty.stok_val = this.orderqty.max_stock
            }
            if (data < this.orderqty.min_stock) {
                this.orderqty.stok_val = this.orderqty.min_stock
            }
        },
        btnEditStok(val) {
            this.dialogVisibleDialogStok = true
            this.tempIdStok = val
            let find_p = this.model.details.filter(dat => dat.id_data == val)
            this.orderqty.stok_val = find_p[0].qty
            this.orderqty.min_stock = find_p[0].min_stock
            this.orderqty.max_stock = find_p[0].max_stock
        },
        btnEditVendorKurir(id_data,branch_id,product_id,vendor_kurir_id="") {
            this.dialogVisibleDialogVendorKurir = true
            this.tempIdVendorKurir.id_data = id_data
            this.tempIdVendorKurir.branch_id = branch_id
            this.tempIdVendorKurir.product_id = product_id
            console.log(this.model.details)
            console.log(this.tempIdVendorKurir)
            this.fetchVendorKurir()
            this.vendor_kurir_id = vendor_kurir_id
        },
        btnStokSave(val) {
            let find_p = this.model.details.filter(dat => dat.id_data == val)
            if (this.orderqty.stok_val > this.orderqty.max_stock) {
                this.dialogVisibleDialogStok = false
                Swal.fire({
                    title: "Gagal",
                    text: 'Order Qty tidak boleh melebihi maksimal stok!',
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Oke",
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                }).then(() => {
                    this.dialogVisibleDialogStok = true
                })
            } else if (this.orderqty.stok_val < this.orderqty.min_stock) {
                this.dialogVisibleDialogStok = false
                Swal.fire({
                    title: "Gagal",
                    text: 'Order Qty tidak boleh kurang dari minimal stok!',
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Oke",
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                }).then(() => {
                    this.dialogVisibleDialogStok = true
                })
            } else {
                find_p[0].qty = this.orderqty.stok_val
                // find_p[0].sub_total_perproduk = this.orderqty.stok_val * find_p[0].unit_price
                this.hitung()
                this.forceUpdateTable()
                this.dialogVisibleDialogStok = false
                this.orderqty.stok_val = null
                this.orderqty.min_stock = null
                this.orderqty.max_stock = null
            }
        },
        btnSaveVendorKurir(val){
            if(this.vendor_kurir_id == ""){
                this.dialogVisibleDialogVendorKurir = false
                Swal.fire({
                    title: "Gagal",
                    text: 'Vendor kurir tidak boleh kosong!',
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Oke",
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                }).then(() => {
                    this.dialogVisibleDialogVendorKurir = true
                })
            }else{
                let f_data = this.lsVendorKurir.filter(datas => datas.value == this.vendor_kurir_id)
                this.vendor_name = f_data[0].label
                let find_p = this.model.details.filter(data => data.id_data == val)
                find_p[0].vendor_kurir_id = this.vendor_kurir_id
                find_p[0].vendor_name = this.vendor_name
                this.forceUpdateTable()
                this.dialogVisibleDialogVendorKurir = false
                this.vendor_kurir_id = ""
                this.vendor_name = ""
            }
        },
        selectAttr(key) {
            document.getElementsByClassName('Select' + key)[0].setAttribute("id", 'list' + key)
        },
        fetchPOCode(branch, dept) {
            let param = {
                branch_id: branch,
                department_code: dept,
            }
            this.$apiservice.setHeader()
            this.$apiservice.post('/purchaseorder/v1/get-last-number', param).then(res => {
                let data = res.data
                this.po_code = data
                this.model['po_code'] = data
                this.forceUpdate()
            })
        },
        changeFile(val, key, keyurl) {
        let files = val.target.files[0];
        if (files.type == "application/pdf") {
            if (files.size > 10000000) {
            Swal.fire({
                text: "Gagal, ukuran file maksimal 10MB",
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Oke",
                customClass: {
                confirmButton: "btn btn-primary",
                },
            });
            } else {
            this.model[key] = files.name;
            this.attachment_filename = files.name;
            this.createBase64url(files, keyurl);
            }
        } else {
            Swal.fire({
            text: "Gagal, format file harus .pdf",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Oke",
            customClass: {
                confirmButton: "btn btn-primary",
            },
            });
        }
        },
        createBase64url(files, keyurl) {
        var reader = new FileReader();
        reader.onload = function (e) {
            // this.model[keyurl] = e.target.result
            this.model[keyurl] = e.target.result
            .replace("data:", "")
            .replace(/^.+,/, "");
        }.bind(this);
        reader.onerror = function (error) {
            alert(error);
        };
        reader.readAsDataURL(files);
        },
        fetchUserProfile() {
            this.$apiservice.query('/user/v1/get-user-detail', { params: { nip: this.profile.data.nip } }).then(res => {
                let data = res.data
                let branch_id = data.branch_id
                let department_code = data.department_code
                this.filter.department_procurement_code = data.department_code
                this.fetchDataPR()
                if (!this.$route.params.id) {
                    if (branch_id != '' && department_code != '') {
                        this.fetchPOCode(branch_id, department_code)
                    }
                }
            })
        },
        selectVendor() {
            document.getElementsByClassName('vendorSelect')[0].setAttribute("id", "selectVendorChange")
        },
        selectVendorKurir() {
            document.getElementsByClassName('vendorSelectKurir')[0].setAttribute("id", "selectVendorKurirChange")
        },
        cariPR(val) {
            let n = val.target.value
            if (n.length > 0) {
                let data = this.listDataPR
                let cari = data.filter(datas => datas.pr_code == n)
                this.listDataPR = cari
                this.forceUpdateTablePR()
            } else {
                this.fetchDataPR()
            }
        },
        addProduct() {
            this.dialogVisibleDialog = true
            if (this.model.details.length > 0) {
                this.loadingDialog = true;
                this.listValueProduct = []
                for (var i in this.model.details) {
                    let row = this.model.details[i]
                    this.listValueProduct.push(row.pr_code)
                }
                this.loadingDialog = false;
            } else {
                this.listValueProduct = []
            }
        },
        fetchDataPR() {
            this.$apiservice.post('/purchaserequest/v1/get-list-purchase-order', this.filter).then(res => {
                let data = res.data.data
                this.listDataPR = []
                for (var i in data) {
                    this.listDataPR.push({ ...data[i] })
                }
                this.forceUpdateTablePR()
            })
        },

        handleSelect(val, key) {
            if (key == 'vendor') {
                if(val == '' || val == null){
                    this.fetchVendor()
                }else{
                    // this.$apiopex.get('/vendor/v1/get-vendor?vendorID=' + val).then(res => {
                    //     let data = res.data.data
                    //     this.model.bank_id = data.bank_id_fk
                    //     this.model.norek = data.nomor_rekening
                    //     this.model.kantor_cabang_bank = data.kantor_cabang_bank
                    //     this.model.notelp = data.vendor_phone
                    //     this.model.alamat = data.vendor_address
                    //     // this.model.reason = data.notes
                    //     this.model.vendor_type_id = data.vendor_type_id_fk
                    //     this.forceUpdate()
                    // })
                }
            } else if (key == 'vendor_b'){
                if(val == '' || val == null){
                    this.fetchVendorB()
                }
            } else if (key == 'vendor_kurir'){
                if(val == '' || val == null){
                    this.fetchVendorKurir()
                }else{
                    let f_data = this.lsVendorKurir.filter(datas => datas.value == val)
                    this.vendor_name = f_data[0].label
                    this.forceUpdate()
                }
            } else if (key == 'po_type') {
                if (val == 'MSTR') {
                    this.dsPoMaster = true
                    this.fields.list[1].subfields[1].required = false
                    this.fields.list[4].subfields[3].required = false
                    this.fields.list[4].subfields[0].required = true
                    this.fields.list[4].subfields[1].required = true
                    this.fields.list[4].subfields[0].disabled = false
                    this.fields.list[4].subfields[1].disabled = false
                    this.dsDept = true
                    this.model.delivery_department_code = ''
                    this.model.delivery_vendor_id = ''
                    this.model.po_code_master = ''
                    this.nameroute = 'procurement-master-purchase-order'
                } else {
                    this.dsPoMaster = false
                    this.dsDept = true
                    this.fields.list[1].subfields[1].required = false
                    this.fields.list[4].subfields[3].required = false
                    this.fields.list[4].subfields[2].disabled = true
                    this.fields.list[4].subfields[2].required = false
                    this.fields.list[4].subfields[0].disabled = true
                    this.fields.list[4].subfields[1].disabled = true
                    this.fields.list[4].subfields[0].required = false
                    this.fields.list[4].subfields[1].required = false
                    this.model.delivery_department_code = ''
                    this.model.delivery_vendor_id = ''
                    this.model.po_code_master = ''
                    this.nameroute = 'procurement-purchase-order'
                }
                this.forceUpdate()
            } else if (key == 'branch') {
                // if(!this.$route.params.id){
                //     if(this.model.department_code != '' && this.model.delivery_branch_id != ''){
                //         this.fetchPOCode(this.model.delivery_branch_id,this.model.department_code)
                //     }
                // }
            } else if (key == 'delivery_type') {
                if (this.model.delivery_type == 'CBNG') {
                    this.model.delivery_department_code = this.model.department_code
                    this.model.delivery_branch_id = ''
                    this.model.delivery_vendor_id = ''
                    this.dsDept = false
                    this.fields.list[4].subfields[3].required = true
                    this.fields.list[4].subfields[2].required = true
                    this.fields.list[4].subfields[2].disabled = false
                    this.fields.list[4].subfields[1].required = false
                    this.fields.list[4].subfields[1].disabled = true
                } else {
                    this.model.delivery_department_code = ''
                    this.model.delivery_branch_id = ''
                    this.model.delivery_vendor_id = ''
                    this.dsDept = true
                    this.fields.list[4].subfields[3].required = false
                    this.fields.list[4].subfields[2].required = false
                    this.fields.list[4].subfields[2].disabled = true
                    this.fields.list[4].subfields[1].required = true
                    this.fields.list[4].subfields[1].disabled = false
                }
                this.forceUpdate()
            } else if (key == 'department') {
                this.filter.department_procurement_code = val
                this.listValueProduct = []
                this.listDataPR = []
                this.fetchDataPR()
                // if(!this.$route.params.id){
                //     if(this.model.department_code != '' && this.model.delivery_branch_id != ''){
                //         this.fetchPOCode(this.model.delivery_branch_id,this.model.department_code)
                //     }
                // }
                this.forceUpdate()
            }
        },
        btnSave() {
            this.$store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading")
            if (this.listValueProduct.length == 0) {
                this.dialogVisibleDialog = false
                this.$store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading")
                this.loading = true;
                this.$refs.tSave.setAttribute("data-kt-indicator", "on");
                this.$refs.tSave.disabled = true;
                Swal.fire({
                    title: "Gagal",
                    text: "Data PR tidak boleh kosong!",
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Oke",
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                }).then(() => {
                    this.dialogVisibleDialog = true
                    this.loading = false;
                    this.$refs.tSave.removeAttribute("data-kt-indicator");
                    this.$refs.tSave.disabled = false;
                });
                return false
            }
            this.loading = true;
            this.$refs.tSave.setAttribute("data-kt-indicator", "on");
            this.$refs.tSave.disabled = true;
            let data = this.listValueProduct
            let total = 0
            this.model.details = []
            for (var i in data) {
                this.$apiservice.setHeader();
                this.$apiservice.query('/purchaserequest/v1/detail', { params: { prCode: data[i] } }).then(resd => {
                    let detail = resd.data
                    let data_produk = detail.details
                    let data_header = detail.header
                    let is_enable_vendor_kurir = false;

                    for (var d in data_produk) {
                        let row = data_produk[d]
                        // let row_header = data_header[d]
                        this.$apiservice.setHeader()
                        this.$apiservice.query('/product/v1/detail', { params: { productId: row.product_id } }).then(res => {
                            if (res.data.error) {
                                row['product_name'] = '-'
                            } else {
                                row['product_name'] = res.data.data.product.product_name
                            }
                            if (res.data.data.product.asset_category == null || res.data.data.product.asset_category == '') {
                                row['asset_category'] = '-'
                            }
                            if (row.stock_on_hand == null) {
                                row.stock_on_hand = '-'
                            }
                            if (row.lob_name == "") {
                                row.lob_name = '-'
                            }

                            if (row.branch == "") {
                                row.branch = '-'
                            }

                            if (row.department == "") {
                                row.department = '-'
                            }
                            this.$apiservice.post('/vendor/v1/get-region-kurir-combined', { q: '', limit: 1, branch_id: data_header.branch_id, product_id: row.product_id }).then(resregion => {
                                is_enable_vendor_kurir = resregion.data.is_enable_vendor_kurir
                                this.model.details.push({
                                    id_data: row.id,
                                    pr_code: detail.header.pr_code,
                                    product_name: row.product_name,
                                    product_id: row.product_id,
                                    unit_price: (row.sub_total / row.qty),
                                    unit_id: row.unit_id,
                                    stock_on_hand: row.stock_on_hand,
                                    qty: row.qty,
                                    asset: res.data.data.product.asset_category,
                                    min_stock: res.data.data.product.min_stock,
                                    max_stock: res.data.data.product.max_stock,
                                    sub_total: row.sub_total,
                                    sub_total_perproduk: row.sub_total,
                                    vendor_kurir_id : '',
                                    vendor_name : '',
                                    is_enable_vendor_kurir : is_enable_vendor_kurir,
                                    branch_id: data_header.branch_id,
                                    branch_name: row.branch,
                                    departement_name: row.department,
                                    lob_name: row.lob_name,
                                    updated_date: row.updated_date
                                })
    
                                total += row.sub_total
    
                                this.model.nilai_pengajuan = this.$mix.formatCurrency(total, 'IDR')
    
                                this.nilai_pengajuan_sementara = total
    
                                this.model.tot_pengajuan = this.$mix.formatCurrency(total, 'IDR')
    
                                // this.forceUpdate()
                                this.forceUpdateTable()
                            })
                        })
                    }
                })
            }
            this.hitung()
            // this.dialogVisibleDialog = false
            // this.$store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading")
        },
        deletePR(val) {
            let new_list = this.model.details.filter(data => data.id_data != val)
            this.model.details = new_list
            this.hitung()
            this.forceUpdateTable()
        },
        onlyUnique(value, index, self) {
            return self.indexOf(value) === index;
        },
        cariVendor(val) {
            this.loadingSelect = true;
            setTimeout(() => {
                this.loadingSelect = false;
                this.fetchVendor(val.toLowerCase())
            }, 200)
        },
        cariVendorB(val) {
            this.loadingSelect = true;
            setTimeout(() => {
                this.loadingSelect = false;
                this.fetchVendorB(val.toLowerCase())
            }, 200)
        },
        cariVendorKurir(val) {
            this.loadingSelect = true;
            setTimeout(() => {
                this.loadingSelect = false;
                this.fetchVendorKurir(val.toLowerCase())
            }, 200)
        },
        hitung(val = null) {
            let list = this.model.details
            let subtotal = 0
            let total = 0
            let ppn = 0
            for (var i in list) {
                subtotal += list[i].unit_price * list[i].qty

                list[i].sub_total_perproduk = list[i].unit_price * list[i].qty
            }
            if (val != null) {
                ppn = Number(val)
                total = (ppn * subtotal / 100) + subtotal
                if (val == '0' || val == '') {
                    total = subtotal
                }
            } else {
                total = subtotal
            }

            this.nilai_pengajuan_sementara = subtotal
            this.model.nilai_pengajuan = this.$mix.formatCurrency(subtotal, 'IDR')
            this.model.tot_pengajuan = this.$mix.formatCurrency(total, 'IDR')

            this.hitungDiskon()

        },
        fetchData() {
            this.$apiservice.setHeader();
            this.$apiservice.query('/purchaseorder/v1/detail', { params: { poCode: this.$route.params.id } }).then(res => {
                this.model = {}
                let data = res.data
                for (var j of Object.keys(data)) {
                    for (var k of Object.keys(data.header)) {
                        if (k == 'grand_total') {
                            // this.model['nilai_pengajuan'] = this.$mix.formatCurrency(data.header['grand_total'],'IDR')
                            this.model['nilai_pengajuan'] = data.header['grand_total']
                        }
                        if (k == 'total_after_ppn') {
                            this.model['tot_pengajuan'] = this.$mix.formatCurrency(data.header['total_after_ppn'], 'IDR')
                        }
                        if (k == 'ppn') {
                            this.model['ppn'] = this.$mix.formatCurrency(data.header['ppn'], 'IDR')
                            this.ppn = data.header['ppn']
                        }

                        this.model[k] = data.header[k]
                    }
                }
                if (data.details.length > 0) {
                    this.model.details = []
                    for (var i in data.details) {
                        let row = data.details[i]
                        if (row.asset_code == null || row.asset_code == '') {
                            row['asset'] = '-'
                        }
                        row['id_data'] = row.id
                        row['id'] = row.id
                        if (row['stock_on_hand'] == null) {
                            row['stock_on_hand'] = '-'
                        }
                        row.is_enable_vendor_kurir = row.is_enable_vendor_kurir == 1 ? true : false
                        row.branch_id = data.header.branch_id
                        this.model.details.push({ ...row })
                        this.listValueProduct.push(row.pr_code)
                    }
                }
                this.model['modified_by'] = this.profile.data.username
                this.handleSelect(this.model.vendor_id, 'vendor')
                this.handleSelect(this.model.department_code, 'department')
                this.handleSelect(this.model.po_type, 'po_type')
                this.hitung()
                this.forceUpdate()
            }).catch(err => {
                if (err.response.status == 401) {
                    this.$router.push({ name: '401' })
                } else if (err.response.status == 404) {
                    this.$router.push({ name: '404' })
                } else if (err.response.status == 500) {
                    this.$router.push({ name: '500' })
                }
            })
        },
        fetchDepartment() {
            this.$apiservice.get('/department/v1/get-all').then(res => {
                let data = res.data
                this.lsDepartment = []
                for (var i in data) {
                    this.lsDepartment.push(
                        {
                            value: data[i].department_code,
                            label: data[i].department_name
                        }
                    )
                }
            })
        },
        fetchVendor(item = null) {
            // this.$apiopex.post('/vendor/v1/get-vendor-autocomplete', { q: item, limit: 100, is_fps: true, status: 1 }).then(res => {
            //     let data = res.data.data
            //     this.lsVendor = []
            //     for (var i in data) {
            //         this.lsVendor.push({
            //             label: data[i].text,
            //             value: data[i].id
            //         })
            //     }
            // })
        },
        fetchVendorB(item = null) {
            // this.$apiopex.post('/vendor/v1/get-vendor-autocomplete', { q: item, limit: 100, is_fps: true }).then(res => {
            //     let data = res.data.data
            //     this.lsVendorB = []
            //     for (var i in data) {
            //         this.lsVendorB.push({
            //             label: data[i].text,
            //             value: data[i].id
            //         })
            //     }
            // })
        },
        fetchVendorKurir(item = null) {
            // this.$apiservice.post('/vendor/v1/get-region-kurir-combined', { q: item, limit: 100, branch_id: this.tempIdVendorKurir.branch_id, product_id: this.tempIdVendorKurir.product_id }).then(res => {
            this.$apiservice.post('/vendor/v1/get-region-kurir-combined', { q: item, limit: 100, branch_id: "428", product_id: "R00368" }).then(res => {
                let data = res.data.data
                this.lsVendorKurir = []
                for (var i in data) {
                    this.lsVendorKurir.push({
                        label: data[i].text,
                        value: data[i].id
                    })
                }
            })
        },
        cariPO(val) {
            this.loadingSelect = true;
            setTimeout(() => {
                this.loadingSelect = false;
                this.fetchPO(val.toLowerCase())
            }, 200)
        },
        fetchPO(item = '') {
            this.$apiservice.post('/purchaseorder/v1/get-po-autocomplete', { q: item, limit: 100, po_type: 'MSTR', approval_status: 'STCPT', department_code:this.model.department_code }).then(res => {
                let data = res.data
                this.lsPoMaster = []
                for (var i in data) {
                    this.lsPoMaster.push({
                        label: data[i].text,
                        value: data[i].id
                    })
                }
            })
        },
        cariBranch() {
            this.$apihris.get('/master/v2/list-subdepartment-group').then(res => {
                let data = res.data.data
                this.lsBranch = []
                for (var i in data) {
                    this.lsBranch.push({
                        value: data[i].sub_department_group_code,
                        label: data[i].sub_department_group_name,
                    })
                }
            })
        },
        fetchVendorType() {
            // this.$apiopex.post('/vendor/v1/get-vendor-type-autocomplete', { q: '', limit: 100 }).then(res => {
            //     let data = res.data.data
            //     this.fields.list[3].options = []
            //     for (var i in data) {
            //         this.fields.list[3].options.push({
            //             value: data[i].id,
            //             label: data[i].text
            //         })
            //     }
            //     // 
            // })
        },
        fetchPoType() {
            this.$apiservice.get('/reference/v1/get-po-types').then(res => {
                let data = res.data
                this.lsPoType = []
                for (var i in data) {
                    this.lsPoType.push({
                        value: data[i].id,
                        label: data[i].text
                    })
                }
                if (this.stand == 'po') {
                    this.model.po_type = 'STDT'
                    this.handleSelect('STDT', 'po_type')
                } else {
                    this.model.po_type = 'MSTR'
                    this.handleSelect('MSTR', 'po_type')
                }
            })
        },
        fetchDelivery() {
            this.$apiservice.get('/reference/v1/get-delivery-types').then(res => {
                let data = res.data
                this.lsDelivery = []
                for (var i in data) {
                    this.lsDelivery.push({
                        value: data[i].id,
                        label: data[i].text
                    })
                }
            })
        },
        forceUpdate() {
            this.refreshForm = false
            this.$nextTick(() => {
                this.refreshForm = true
            })
        },
        forceUpdateTable() {
            this.dialogVisibleDialog = false
            this.$store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading")
            this.loading = false;
            this.$refs.tSave.removeAttribute("data-kt-indicator");
            this.$refs.tSave.disabled = false;
            this.refreshTable = false
            this.$nextTick(() => {
                this.refreshTable = true
            })
        },
        forceUpdateTablePR() {
            this.refreshTablePR = false
            this.$nextTick(() => {
                this.refreshTablePR = true
            })
        },

        checkAll() {
            this.isCheckAll = !this.isCheckAll;
            this.listValueProduct = [];
            if (this.isCheckAll) { // Check all
                for (var key in this.listDataPR) {
                    this.listValueProduct.push(this.listDataPR[key].pr_code);
                }
            }
        },
        updateCheckall() {
            if (this.listValueProduct.length == this.listDataPR.length) {
                this.isCheckAll = true;
            } else {
                this.isCheckAll = false;
            }
        },

        hitungDiskon(val = 0) {
            this.model.discount = Number(val)
            if (Number(this.model.discount) > this.nilai_pengajuan_sementara) {
                Swal.fire({
                    title: "Peringatan",
                    text: 'Diskon tidak boleh melebihi nilai pengajuan !',
                    icon: "info",
                    buttonsStyling: false,
                    confirmButtonText: "Oke",
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                }).then(() => {
                    this.model.discount = Number(this.nilai_pengajuan_sementara)
                    let total_sementara = Number(this.nilai_pengajuan_sementara) - this.model.discount;

                    this.model.tot_pengajuan = this.$mix.formatCurrency(total_sementara, 'IDR')
                })
            } else {
                let total_sementara = Number(this.nilai_pengajuan_sementara) - Number(this.model.discount);

                this.model.tot_pengajuan = this.$mix.formatCurrency(total_sementara, 'IDR')
            }
        },

    },
}
</script>
<style>
.el-select {
    width: 100%;
}

.for-cari::placeholder {
    font-weight: 600 !important;
    font-family: Arial, 'Font Awesome 5 Free'
}
</style>
